import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Autocomplete,
  Typography,
  TablePagination,
} from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";
import { Fragment, useEffect, useRef, useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import InputField from "../../Components/InputField/InputField";
import moment from "moment";
import ReportServices from "../../Api/Report/Report.index";
import { Save as SaveIcon } from "@mui/icons-material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import SimpleDialog from "../../Components/Dialog/Dialog";
import { Controller } from "react-hook-form";
import UserServices from "../../Api/UserServices/User.index";
import ProjectServices from "../../Api/ProjectServices/Project.index";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Storage from "../../Utils/Storage";
import { jwtDecode } from "jwt-decode";
import { ErrorToaster, SuccessToaster } from "../../Components/Toaster/Toaster";
import { CSVLink } from "react-csv";

// function DataFilter({
//   sortData,
//   onMonthChange,
//   selectedMonth,
//   setSelectedMonth,
// }) {
//   const [filterCollapse, setFilterCollapse] = useState(true);
//   const [names, setNames] = useState([]);
//   const [projects, setProjects] = useState([]);
//   // for search
//   const [usersSearch, setUsersSearch] = useState("");
//   const [projectsSearch, setProjectsSearch] = useState("");

//   const { setStorageItem, getStorageItem } = Storage();
//   const [user, setUser] = useState(getStorageItem("user"));

//   const decodedToken = jwtDecode(user.token);
//   const userIdFromToken = decodedToken.id;
//   const company_id = userIdFromToken;


//   const months = moment.months();
//   const currentMonth = moment().month();

//   const filterData = () => {
//     sortData(usersSearch, projectsSearch);
//   };

//   const clearData = () => {
//     sortData("");
//     setUsersSearch("");
//     setProjectsSearch("");
//   };
//   const handleGetUsers = async (page, limit, search, status, companyId) => {
//     try {
//       const { data, responseCode, message } = await UserServices.getUser(
//         page,
//         limit,
//         search,
//         status ? status : "",
//         companyId ? companyId : company_id
//       );
//       const filteredName = data?.output?.users.filter(
//         (email) => email.role !== "admin" && email.role !== "company"
//       );
//       setNames(filteredName);
//       // setTotalCount(data?.output?.totalUsers);
//       console.log(data);
//     } catch (error) {
//       console.error("Error while fetching users:", error);
//     }
//   };
//   const getProjects = async (
//     newPage,
//     rows,
//     name,
//     assignedUser,
//     priority,
//     statuses
//   ) => {
//     const { data, responseCode } = await ProjectServices.getProject(
//       newPage ? newPage : 1,
//       rows ? rows : "",
//       name ? name : "",
//       assignedUser ? assignedUser : "",
//       priority ? priority : "",
//       statuses ? statuses : ""
//     );
//     try {
//       setProjects(data?.output?.projects);
//       // setCount(data?.output?.totalProjects);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleUserChange = (event, value) => {
//     setUsersSearch(value?._id);
//     console.log(usersSearch);
//   };
//   const handleProjectChange = (event, value) => {
//     setProjectsSearch(value?._id);
//   };
//   useEffect(() => {
//     handleGetUsers(1, 1000, "", "", company_id);
//     getProjects(1, 1000, "", "", "", "", "");
//   }, []);
//   return (
//     <Box sx={{mx:2 }}>
//       <Box
//         component={Paper}
//         sx={{ bgcolor: Colors.white, p: 2, borderRadius: "12px", mt: 4 }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <Typography
//             variant="body1"
//             fontWeight="bold"
//             sx={{ color: Colors.primary }}
//           >
//             Filters
//           </Typography>
//           <IconButton
//             onClick={() => setFilterCollapse(!filterCollapse)}
//             sx={{ bgcolor: Colors.primaryLight, color: Colors.primary }}
//           >
//             <FilterAlt />
//           </IconButton>
//         </Box>

//         <Collapse in={filterCollapse}>
//           <Grid container spacing={2} justifyContent="space-between">
//             <Fragment>
//               <Grid item md={6} sx={{ width: "100%" }}>
//                 <InputLabel>Search By Name</InputLabel>

//                 <Autocomplete
//                   options={names}
//                   getOptionLabel={(option) => option.name || ""}
//                   // isOptionEqualToValue={(option, value) => option.id === value.id}
//                   value={names.find((name) => name._id === usersSearch) || null}
//                   onChange={handleUserChange}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       InputProps={{
//                         ...params.InputProps,
//                         endAdornment: <>{params.InputProps.endAdornment}</>,
//                       }}
//                     />
//                   )}
//                 />
//               </Grid>
//               <Grid item md={6} sx={{ width: "100%" }}>
//                 <InputLabel>Search By Project</InputLabel>

//                 <Autocomplete
//                   options={projects}
//                   getOptionLabel={(option) => option.name || ""}
//                   // isOptionEqualToValue={(option, value) => option.id === value.id}
//                   value={
//                     projects.find(
//                       (project) => project._id === projectsSearch
//                     ) || null
//                   }
//                   onChange={handleProjectChange}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       fullWidth
//                       InputProps={{
//                         ...params.InputProps,
//                         endAdornment: <>{params.InputProps.endAdornment}</>,
//                       }}
//                     />
//                   )}
//                 />
//               </Grid>
             
//               <Grid item md={6} sx={{ width: "100%" }}>
//                 {/* <FormControl fullWidth> */}
//                 <InputLabel>Select Month</InputLabel>
//                 <TextField
//                   select
//                   fullWidth
//                   value={selectedMonth}
//                   onChange={(e) => {
//                     setSelectedMonth(e.target.value);
//                     onMonthChange(e.target.value);
//                   }}
//                 >
//                   {months.map((month, index) => (
//                     <MenuItem
//                       disabled={index > currentMonth}
//                       key={month}
//                       value={month}
//                     >
//                       {month}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//                 {/* </FormControl> */}
//               </Grid>
//             </Fragment>
//             <Grid item xs={12} display="flex" justifyContent="flex-end">
//               <Button
//                 onClick={clearData}
//                 // variant="outlined"
//                 sx={{
//                   color: Colors.primary,
//                   mx: 1,
//                   textTransform: "capitalize",
//                   minWidth: "100px",
//                   boxShadow:
//                     "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
//                 }}
//               >
//                 Clear
//               </Button>
//               <Button
//                 onClick={filterData}
//                 variant="contained"
//                 sx={{
//                   textTransform: "capitalize",
//                   minWidth: "100px",
//                   boxShadow:
//                     "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
//                   color: Colors.white,
//                   background: Colors.primary,
//                   "&:hover": {
//                     background: Colors.primary,
//                   },
//                 }}
//               >
//                 Search
//               </Button>
//             </Grid>
//           </Grid>
//         </Collapse>
//       </Box>
//     </Box>
//   );
// }
function DataFilter({
  sortData,
  onMonthChange,
  selectedMonth,
  setSelectedMonth,
}) {
  const [filterCollapse, setFilterCollapse] = useState(true);
  const [names, setNames] = useState([]);
  const [projects, setProjects] = useState([]);
  // for search
  const [usersSearch, setUsersSearch] = useState("");
  const [projectsSearch, setProjectsSearch] = useState("");

  const { setStorageItem, getStorageItem } = Storage();
  const [user, setUser] = useState(getStorageItem("user"));

  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const company_id = userIdFromToken;

  const generateLast12Months = () => {
    const months = [];
    const now = moment();
    for (let i = 0; i < 12; i++) {
      months.unshift(now.format("MMMM YYYY"));
      now.subtract(1, "month");
    }
    return months;
  };

  const months = generateLast12Months();
  const currentMonth = moment().format("MMMM YYYY");

  const filterData = async () => {
    if (!selectedMonth) {
      console.error("No month selected");
      return;
    }
  
    const [monthName, year] = selectedMonth.split(" ");
    const monthIndex = moment().month(monthName).format("MM");
    const formattedDate = `${year}-${monthIndex}`;
  
    console.log("Filtering data for:", formattedDate);
  
    try {
      const data = await sortData(usersSearch, projectsSearch, formattedDate);
      const updatedData = generateDaysForProjects(data, formattedDate);
      console.log("Updated Data with Days:", updatedData);
    } catch (error) {
      console.error("Error during filtering:", error);
    }
  };
  
  const generateDaysForProjects = (data, formattedDate) => {
    const [year, month] = formattedDate.split("-");
    const totalDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    
    data.forEach((user) => {
      user.projects.forEach((project) => {
        if (!project.days || project.days.length === 0) {
          project.days = [];
        }
        for (let i = 1; i <= totalDays; i++) {
          const dayDate = `${year}-${month}-${String(i).padStart(2, "0")}`;
          if (!project.days.some((day) => day.date === dayDate)) {
            project.days.push({
              date: dayDate,
              hour: 0,
            });
          }
        }
      });
    });
  
    return data;
  };
  
  const clearData = () => {
    sortData("");
    setUsersSearch("");
    setProjectsSearch("");
  };
  const handleGetUsers = async (page, limit, search, status, companyId) => {
    try {
      const { data, responseCode, message } = await UserServices.getUser(
        page,
        limit,
        search,
        status ? status : "",
        companyId ? companyId : company_id
      );
      const filteredName = data?.output?.users.filter(
        (email) => email.role !== "admin" && email.role !== "company"
      );
      setNames(filteredName);
      console.log(data);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };
  const getProjects = async (
    newPage,
    rows,
    name,
    assignedUser,
    priority,
    statuses
  ) => {
    const { data, responseCode } = await ProjectServices.getProject(
      newPage ? newPage : 1,
      rows ? rows : "",
      name ? name : "",
      assignedUser ? assignedUser : "",
      priority ? priority : "",
      statuses ? statuses : ""
    );
    try {
      setProjects(data?.output?.projects);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserChange = (event, value) => {
    setUsersSearch(value?._id);
    console.log(usersSearch);
  };
  const handleProjectChange = (event, value) => {
    setProjectsSearch(value?._id);
  };
  useEffect(() => {
    handleGetUsers(1, 1000, "", "", company_id);
    getProjects(1, 1000, "", "", "", "", "");
  }, []);
  
  return (
    <Box sx={{mx:2 }}>
      <Box
        component={Paper}
        sx={{ bgcolor: Colors.white, p: 2, borderRadius: "12px", mt: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: Colors.primary }}
          >
            Filters
          </Typography>
          <IconButton
            onClick={() => setFilterCollapse(!filterCollapse)}
            sx={{ bgcolor: Colors.primaryLight, color: Colors.primary }}
          >
            <FilterAlt />
          </IconButton>
        </Box>

        <Collapse in={filterCollapse}>
          <Grid container spacing={2} justifyContent="space-between">
            <Fragment>
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Search By Name</InputLabel>

                <Autocomplete
                  options={names}
                  getOptionLabel={(option) => option.name || ""}
                  value={names.find((name) => name._id === usersSearch) || null}
                  onChange={handleUserChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Search By Project</InputLabel>

                <Autocomplete
                  options={projects}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    projects.find(
                      (project) => project._id === projectsSearch
                    ) || null
                  }
                  onChange={handleProjectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
             
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Select Month</InputLabel>
                <TextField
                  select
                  fullWidth
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.target.value);
                    onMonthChange(e.target.value);
                  }}
                >
                  {months.map((month, index) => (
                    <MenuItem
                      key={month}
                      value={month}
                    >
                      {month}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Fragment>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                onClick={clearData}
                sx={{
                  color: Colors.primary,
                  mx: 1,
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                }}
              >
                Clear
              </Button>
              <Button
                onClick={filterData}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  color: Colors.white,
                  background: Colors.primary,
                  "&:hover": {
                    background: Colors.primary,
                  },
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
}
const TableComponent = () => {
  const [totalArray, setTotalArray] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [items, setItems] = useState([]);
  const [usersSearch, setUsersSearch] = useState("");
  const [projectsSearch, setProjectsSearch] = useState("");
  const [hour, setHour] = useState("");
  const [comments, setComments] = useState("");
  const [submissions, setSubmissions] = useState("");
  const [projectName, setProjectName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [comparisonCsvData, setComparisonCsvData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("MMMM YYYY"));
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const currentDateRef = useRef(null);
  const tableRef = useRef();
  const csvLink = useRef();

  console.log(submissions);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDeleteComment = (index) => {
    const submissionIdToDelete = submissions[index];
    console.log(submissionIdToDelete);
    setCommentToDelete({ index, submissionId: submissionIdToDelete });
    setOpenConfirmDialog(true);
  };

  const confirmDeleteComment = async () => {
    try {
      const data = await ReportServices.deleteReport(
        commentToDelete.submissionId
      );
      console.log(data);
      const updatedComments = comments.filter(
        (_, i) => i !== commentToDelete.index
      );
      const updatedSubmissions = submissions.filter(
        (_, i) => i !== commentToDelete.index
      );

      // Update the state
      setComments(updatedComments);
      setSubmissions(updatedSubmissions);
      GetStatsReport();
      SuccessToaster("Submission Deleted Successfully");

      // Close dialogs
      setOpenConfirmDialog(false);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting comment and submission:", error);
    }
  };

  const sortByStatus = (usersSearch, projectsSearch) => {
    setUsersSearch(usersSearch);
    setProjectsSearch(projectsSearch);
    GetStatsReport(
      usersSearch ? usersSearch : "",
      projectsSearch ? projectsSearch : ""
    );
  };
  const GetStatsReport = async (usersSearch, projectsSearch) => {
    try {
      const [monthName, year] = selectedMonth.split(" ");
      const monthIndex = moment().month(monthName).format("MM");
      const formattedDate = `${year}-${monthIndex}`;
      
      const { result } = await ReportServices.getStats(
        usersSearch || "",
        projectsSearch || "",
        formattedDate
      );
  
      const updatedData = getAllDaysOfSelectedMonth(result, year, monthIndex - 1);
      const sortedStatsData = updatedData.sort((a, b) => {
        if (a.user_name < b.user_name) return -1;
        if (a.user_name > b.user_name) return 1;
        return 0;
      });
  
      setStatsData(sortedStatsData);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };
  
  const getAllDaysOfSelectedMonth = (data, year, month) => {
    const totalDays = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();
  
    // Generate days
    const generateDays = (month, year) => {
      const days = [];
      for (let i = 1; i <= totalDays; i++) {
        days.push({
          date: `${year}-${String(month + 1).padStart(2, "0")}-${String(i).padStart(2, "0")}`,
          hour: 0,
        });
      }
      return days;
    };
  
    // Merge days with project data
    data.forEach((user) => {
      user.projects.forEach((project) => {
        const existingDays = project.days || [];
        const generatedDays = generateDays(month, year);
        project.days = mergeDays(existingDays, generatedDays);
      });
    });
  
    return data;
  };
  
  const mergeDays = (existingDays, generatedDays) => {
    return generatedDays.map((day) => {
      const existingDay = existingDays.find((d) => d.date === day.date);
      return existingDay || day;
    });
  };
  
  useEffect(() => {
    GetStatsReport(
      usersSearch ? usersSearch : "",
      projectsSearch ? projectsSearch : ""
    );
  }, []);

  console.log(statsData);
  const updateDaysArray = (monthName, year) => {
    const monthIndex = moment().month(monthName).format("MM") - 1; // Zero-based month index
    const daysInMonth = moment(`${year}-${monthIndex + 1}`, "YYYY-MM").daysInMonth();
  
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => {
      const day = moment(`${year}-${monthIndex + 1}-${i + 1}`, "YYYY-MM-DD");
      return {
        dayOfMonth: day.date(),
        dayOfWeek: day.format("dddd"),
      };
    });
  
    setTotalArray(daysArray);
  };
  
  useEffect(() => {
    const updatedDatas = getAllDaysOfSelectedMonth(statsData);
    console.log(updatedDatas, "updatedDatasupdatedDatas");
    setStatsData(updatedDatas);
    GetStatsReport(
      usersSearch ? usersSearch : "",
      projectsSearch ? projectsSearch : ""
    );
  }, [selectedMonth]);

  useEffect(() => {
    updateDaysArray(selectedMonth , moment().format("YYYY")); 
  }, []);
  console.log(selectedMonth , "===>selectedMonth")

  const handleMonthChange = (monthYear) => {
    setSelectedMonth(monthYear);
    const [monthName, year] = monthYear.split(" ");
    updateDaysArray(monthName, year);
  };
  
  const isSunday = (date) => {
    return moment(date).format("dddd") === "Sunday";
  };
  const handleExport = async () => {
    const printContents = tableRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDialog = (hours, projectName, hour) => {
    if (isSunday(hours.date) && !hours.hour) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
      console.log(hours);
      setComments(hours.comments);
      setSubmissions(hours.submissions);
      setProjectName(projectName);
      setHour(hours.hoursList);
    }
  };
  useEffect(() => {
    if (csvData.length > 0) {
      csvLink?.current.link.click();
    }
  }, [csvData, comparisonCsvData]);

  const downloadCsv = () => {
    setCsvLoading(true);
    try {
      const csvHeaders = [
        { label: "SR", key: "sr" },
        { label: "Name", key: "name" },
        { label: "Projects", key: "projectName" },
        ...totalArray.map((item, index) => ({
          label: `${item.dayOfMonth} ${selectedMonth}`,
          key: `day${index}`,
        })),
        { label: "Total hrs Per Project", key: "totalPerProject" },
        { label: "Total", key: "total" },
      ];
  
      const daysRow = [
        "", "", "",
        ...totalArray.map((item) => item.dayOfWeek),
        "", "",
      ];
  
      const csvRows = statsData.flatMap((user, index) =>
        user.projects.map((project, projIndex) => ({
          sr: index + 1,
          name: projIndex === 0 ? user?.user_name : "",
          projectName: project.project_name?.name,
          ...project?.days.reduce((acc, day, i) => {
            acc[`day${i}`] = day.hour || "OFF";
            return acc;
          }, {}),
          totalPerProject: project?.days?.reduce((sum, day) => sum + day.hour, 0),
          total: projIndex === 0
            ? user.projects.reduce((total, proj) => total + proj.days.reduce((sum, d) => sum + d.hour, 0), 0)
            : "",
        }))
      );
  
      setCsvData([
        csvHeaders.map((header) => header.label),
        daysRow,
        ...csvRows.map((row) => Object.values(row)),
      ]);
    } catch (error) {
      console.error("Error generating CSV:", error);
    } finally {
      setCsvLoading(false);
    }
  };
  
  
  
  return (
    <>
      <Box sx={{ mt: 5, ml: 2  }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "600", fontSize: "20px", color: Colors.primary }}
        >
          Users Report
        </Typography>
      </Box>
      <DataFilter
        onMonthChange={handleMonthChange}
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
        sortData={(usersSearch, projectsSearch) =>
          sortByStatus(usersSearch, projectsSearch)
        }
        usersSearch={usersSearch}
        projectsSearch={projectsSearch}
      />
      
      <Box sx={{ mt: 2,mr:2,ml:2 }}>
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "600",
          textAlign: "left",
        
          
          color: Colors.primary,
          mt: 4,
          display: "flex",
          alignItems: "center", // Align items center
          justifyContent: "flex-end", // Space between text and button
        }}
      >
        <CSVLink
  ref={csvLink}
  data={csvData}
  filename={`Users_Report_${moment().format('DD-MMM-YYYY_HHmmss')}.csv`}
  target="_blank"
>
</CSVLink>

        <Button
          style={{ backgroundColor: Colors.primary ,color:Colors.white}}
          onClick={() => downloadCsv()}
          loading={csvLoading}
        >
          Download Excel
        </Button>
      </Box>
        <TableContainer
          component={Paper}
          sx={{ oveflow: "scroll", height: "400px",mt:2 }}
          ref={tableRef}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ position: "sticky", top: "0px", zIndex: 1 }}>
              <TableRow sx={{ backgroundColor: Colors.primary }}>
                <TableCell sx={{ fontWeight: "bold", color: Colors.black }}>
                  SR
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: Colors.black,
                    position: "sticky",
                    left: "0",
                    background: Colors.primary,
                  }}
                >
                  <Typography
                    sx={{
                      width: "100px",
                      fontWeight: "bold",
                      color: Colors.black,
                      fontSize: "14px",
                    }}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    color: Colors.black,
                    position: "sticky",
                    left: "130px",
                    background: Colors.primary,
                  }}
                >
                  Projects
                </TableCell>
                {totalArray.map((item, index) => (
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: Colors.black,
                    }}
                    key={index}
                  >
                    {item.dayOfMonth} <br />
                    {selectedMonth}
                  </TableCell>
                ))}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontWeight: "bold",
                    color: Colors.black,
                    textAlign: "center",
                  }}
                >
                  Totals
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: "0",
                    background: Colors.white,
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: "0",
                    background: Colors.white,
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    left: "130px",
                    background: Colors.white,
                  }}
                ></TableCell>

                {totalArray.map((item, index) => {
                  const isCurrentDate = moment().isSame(
                    moment().month(selectedMonth).date(item.dayOfMonth),
                    "day"
                  );
                  return (
                    <TableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                      key={index}
                      ref={isCurrentDate ? currentDateRef : null}
                    >
                      {item.dayOfWeek}
                    </TableCell>
                  );
                })}
                <TableCell sx={{ fontWeight: "bold" }}>
                  <Typography
                    sx={{
                      width: "150px",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    Total hrs Per Project
                  </Typography>
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
              </TableRow>
              
              <SimpleDialog
                open={openDialog}
                onClose={() => {
                  setOpenDialog(false);
                }}
                comments={comments}
                title="Comments"
              >
                <Box sx={{ mt: 2, fontWeight: "600" }}>
                  {comments?.length > 0 ? (
                    <ul>
                      {comments.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {projectName} - {item === "" ? "No Comments" : item}{" "}
                            - {hour[index] + "hrs"}
                          </span>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteComment(index)}
                            sx={{ marginLeft: 1 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No Comments"
                  )}
                </Box>
              </SimpleDialog>
              <SimpleDialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                title="Confirm Delete"
              >
                <Box sx={{ mt: 2, fontWeight: "600" }}>
                  <Typography variant="body1">
                    Are you sure you want to delete this comment?
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                  >
                    <Button
                      sx={{
                        border: "1px solid black",
                        color: Colors.black,
                        mr: 2,
                      }}
                      onClick={() => setOpenConfirmDialog(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => confirmDeleteComment()}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </SimpleDialog>

              {statsData?.length > 0 ? (
                statsData.map((user, index) =>
                  user?.projects?.map((project, projIndex) => (
                    <TableRow key={projIndex}>
                      {projIndex === 0 && (
                        <>
                          <TableCell
                            sx={{ border: "4px solid black" }}
                            rowSpan={user.projects.length}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              position: "sticky",
                              left: "0",
                              // zIndex: 1,

                              borderTop: "4px solid black",
                              borderBottom: "4px solid black",
                              position: "sticky",
                              left: "0",
                              background: Colors.white,

                              "&::after": {
                                content: "''",
                                position: "absolute",
                                top: 0,
                                right: "2px",
                                height: "100%",
                                width: "6px",
                                background: "black",
                              },
                              backgroundColor: Colors.white,
                            }}
                            rowSpan={user.projects.length}
                          >
                            <Typography
                              sx={{ width: "100px", fontSize: "13px " }}
                            >
                              {user?.user_name}
                            </Typography>
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        sx={{
                          "&::after": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            right: -1,
                            height: "100%",
                            width: "5px",
                            background: "black",
                          },
                          backgroundColor:
                            projIndex % 2 === 0 ? "rgb(239 240 246)" : " ",
                          // borderLeft: "4px solid black",
                          // borderRight: "4px solid black",
                          borderTop: projIndex === 0 ? "4px solid black" : "",
                          borderBottom:
                            projIndex === user.projects.length - 1
                              ? "4px solid black"
                              : "",
                          position: "sticky",
                          left: "130px",
                          background:
                            projIndex % 2 === 0
                              ? "rgb(239 240 246)"
                              : Colors.white,
                        }}
                      >
                        {project?.project_name?.name}
                      </TableCell>

                      {project?.days?.map((hours, dayIndex) => (
                        <TableCell
                          ref={isSunday(hours.date) ? currentDateRef : null}
                          sx={{
                            fontWeight:
                              (moment().date() === dayIndex + 1 &&
                                selectedMonth == moment().format("MMMM")) ||
                              isSunday(hours.date)
                                ? "600"
                                : "normal",
                            textAlign: "center",
                            backgroundColor:
                              projIndex % 2 === 0 ? "rgb(239 240 246)" : " ",
                            borderTop: projIndex === 0 ? "4px solid black" : "",
                            borderBottom:
                              projIndex === user.projects.length - 1
                                ? "4px solid black"
                                : "",
                            cursor: "pointer",
                            color:
                              isSunday(hours.date) && !hours.hour
                                ? "red"
                                : "black",
                          }}
                          key={dayIndex}
                          // onClick={() => handleDialog(hours)}
                          onClick={() =>
                            handleDialog(hours, project.project_name.name)
                          }
                        >
                          <Tooltip
                            title={
                              hours?.workType?.length > 0 && hours?.workType[0]
                            }
                          >
                            {hours.hour
                              ? hours.hour
                              : isSunday(hours.date) && hours.hour
                              ? hours.hour
                              : !isSunday(hours.date) && !hours.hour
                              ? hours.hour
                              : "OFF"}
                          </Tooltip>
                        </TableCell>
                      ))}

                      <TableCell
                        sx={{
                          textAlign: "center",
                          backgroundColor:
                            projIndex % 2 === 0 ? "rgb(239 240 246)" : " ",
                          fontWeight: "600",
                          borderTop: projIndex === 0 ? "4px solid black" : "",
                          borderBottom:
                            projIndex === user.projects.length - 1
                              ? "4px solid black"
                              : "",
                        }}
                      >
                        {project?.days?.reduce(
                          (acc, curr) => acc + curr.hour,
                          0
                        )}
                      </TableCell>
                      {projIndex === 0 && (
                        <TableCell
                          sx={{
                            textAlign: "center",
                            backgroundColor:
                              projIndex % 2 === 0 ? "rgb(239 240 246)" : " ",
                            fontWeight: "600",
                            border: "4px solid black",
                          }}
                          rowSpan={user?.projects?.length}
                        >
                          {user?.projects?.reduce((total, project) => {
                            return (
                              total +
                              project?.days?.reduce(
                                (acc, curr) => acc + curr.hour,
                                0
                              )
                            );
                          }, 0)}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={15}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "280px",
                        fontSize: "25px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      No data Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 3, mb: 2 }}></Box>
      </Box>
    </>
  );
};

export default TableComponent;
