import {
    Box,
    Typography,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    InputLabel,
    Button,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import moment from "moment";
  import { Colors } from "../../Assets/Styles/Colors";
import { CSVLink } from "react-csv";
import { ErrorToaster } from "../../Components/Toaster/Toaster";
  
  export default function TimeReport() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
  
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [comparisonCsvData, setComparisonCsvData] = useState([]);


  const csvLink = useRef();

  
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
  
    const handleYearChange = (event) => {
      setSelectedYear(event.target.value);
      // Reset month to January if a year greater than the current year is selected
      if (event.target.value > currentYear) setSelectedMonth(0);
    };
  
    const getDaysInMonth = (month, year) => {
      const date = new Date(year, month, 1);
      const days = [];
      while (date.getMonth() === month) {
        days.push({
          date: moment(date).format("DD-MM-YYYY"),
          day: date.toLocaleString("default", { weekday: "long" }),
        });
        date.setDate(date.getDate() + 1);
      }
      return days;
    };
  
    const daysInSelectedMonth = getDaysInMonth(selectedMonth, selectedYear);

    const downloadCsv = () => {
        setCsvLoading(true); 
    
        try {
        
            const csvHeaders = [
                { label: "Date", key: "date" },
                { label: "Day", key: "day" },
                { label: "Check-In", key: "checkIn" },
                { label: "Check-Out", key: "checkOut" },
                { label: "Total Hours", key: "totalHours" },
                { label: "Status", key: "status" },
                { label: "Remarks", key: "remarks" },
              ];
          
    
              const csvData = daysInSelectedMonth.map((day, index) => ({
                date: day.date,
                day: day.day,
                checkIn: "11:00 AM", 
                checkOut: "12:00 PM", 
                totalHours: "7 hours", 
                status: "Late Arrival", 
                remarks: "Ok", 
              }));
    
              setCsvData([csvHeaders.map((header) => header.label), ...csvData.map((row) => Object.values(row))]);
            } catch (error) {
              console.error("Error generating CSV data: ", error);
              ErrorToaster("Failed to generate CSV data: " + error.message);
            } finally {
              setCsvLoading(false);
            }
          };
      useEffect(() => {
        if (csvData.length > 0) {
          csvLink?.current.link.click();
        }
      }, [csvData, comparisonCsvData]);
    return (
      <>
        <Box sx={{ mt: 5, ml: 2 }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "600", color: Colors.primary }}
          >
          User Attendance Report
          </Typography>
        </Box>
  
        <Grid  container spacing={2}   sx={{ mt: 3, ml: 2,mr:2,width:"auto !important" }}>
          {/* Year Selector */}
          <Grid item md={5} sm={12} xs={12}>

          
          <InputLabel>Select Year</InputLabel>
          <Select
          fullWidth
            value={selectedYear}
            onChange={handleYearChange}
            // sx={{ width: 400 }}
          >
            {Array.from({ length: currentYear - 2021 }, (_, i) => (
              <MenuItem key={i} value={2022 + i}>
                {2022 + i}
              </MenuItem>
            ))}
          </Select>
          </Grid>
  
          <Grid item md={5}  sm={12} xs={12}>

         
          <InputLabel>Select Month</InputLabel>
          <Select
          fullWidth
            value={selectedMonth}
            onChange={handleMonthChange}
            // sx={{ width: 400 }}
          >
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem
                key={i}
                value={i}
                disabled={
                  selectedYear === currentYear && i > currentMonth // Disable future months in the current year
                }
              >
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </MenuItem>
            ))}
          </Select>
          </Grid>
        </Grid>
  
        <Box sx={{ mt: 3, ml: 2, mr: 2 }}>
        <CSVLink
            ref={csvLink}
            data={csvData}
            filename={`Users_Attendance_${moment().format("DD-MMM-YYYY_HHmmss")}.csv`}
            target="_blank"
          ></CSVLink>
          <Box
            sx={{
              display: "flex",
              justifyContent: " end",
              width: "100%",
              mr: 2,
            }}
          >
            <Button
              style={{ backgroundColor: Colors.primary, color: Colors.white,ml:"-10px", }}
              onClick={() => downloadCsv()}
              loading={csvLoading}
            >
              Download Excel
            </Button>
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              overflow: "auto",
              height: "400px",
              mt:1
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    color: "#fff",
                    position: "sticky",
                    top: 0,
                    backgroundColor: Colors.primary,
                    zIndex: 1,
                  }}
                >
                  {["Date", "Day", "Check-In", "Check-Out", "Total Hours", "Status", "Remarks"].map(
                    (header) => (
                      <TableCell
                        key={header}
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          textAlign: "center",
                          borderRight: `1px solid ${Colors.grey}`,
                        }}
                      >
                        {header}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {daysInSelectedMonth.map((day, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? Colors.lightGray : "#f9f9f9",
                      "&:hover": { backgroundColor: Colors.hoverGray },
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      {day.date}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      {day.day}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      11:00 AM
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      12:00 PM
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      7 hour
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={{ color: Colors.danger }}>Late Arrival</Typography>   

                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "12px 16px",
                        borderRight: `1px solid ${Colors.grey}`,
                        textAlign: "center",
                      }}
                    >
                      Ok
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  }
  