import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Container,
  CircularProgress,
  IconButton,
  CardMedia,
  Grid,
  Typography,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Styles/Colors";
import { Images } from "../../Assets/Images/Images";
import AuthService from "../../Api/AuthServices/Auth.index";
import { useAuth } from "../../Context/UseContext";
import { ErrorToaster, SuccessToaster } from "../../Components/Toaster/Toaster";
import SimpleDialog from "../../Components/Dialog/Dialog";
import InputField from "../../Components/InputField/InputField";
import AccountSettingServices from "../../Api/AccountSettingServices/AccountSettingServices.index";
import FingerprintJS from "@fingerprintjs/fingerprintjs";


export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fingerprint, setFingerprint] = useState("");
  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      console.log(fp);
      const result = await fp.get();
      console.log(result);
      setFingerprint(result.visitorId); // Unique fingerprint ID
    };
    loadFingerprint();
  }, []);
 
  console.log(fingerprint)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    setValue,
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset,
  } = useForm();

  const { userLogin } = useAuth();
  const navigate = useNavigate();

  const login = async (formData) => {
    setLoading(true);
    try {
      let obj = {
        email: formData.email,
        password: formData.password,
      };
      const { status, responseCode, data, message } = await AuthService.login(
        obj
      );
      userLogin(data);
      SuccessToaster(message);
      if (responseCode == 200 && data.role == "admin") {
        navigate("/createcompany");
      } else if (responseCode == 200 && data.role == "company") {
        navigate("/dashboard");
      } else if (responseCode == 200 && data.role == "user"){
        navigate("/timesheet");
      }
    } catch (error) {
      ErrorToaster(error?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleDialog = () => {
    setOpenDialog(true);
  };
  const handleForgotPassword = async (formData) => {
    try {
      let obj = {
        email: formData.email,
        newPassword: formData.password,
      };
      const { status, responseCode, data, message } =
        await AccountSettingServices.forgotPassword(obj);
      SuccessToaster(message);
    } catch (error) {
      ErrorToaster(error?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        component="main"
        maxWidth="md"
      >
        <Grid
          container
          alignItems="center"
          
          sx={{
            borderRadius: "20px",
            padding:"0px 15px",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
            flexDirection: {
              xs: "column-reverse",
              sm: "row",
             
            },
          }}
        >
          <Grid item xs={12} sm={6}>
            <CardMedia
              component={"img"}
              // width="120px"
              // height="90px"
              src={Images.login}
              sx={{
                objectFit: "contain",
                borderRadius: "20px",
                display: { xs: "none", sm: "flex" },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ background: Colors.white, padding: "57px 20px" }}>
              <CardMedia
                component={"img"}
                width={"120px"}
                height={"90px"}
                src={Images.logo}
                sx={{ objectFit: "contain" }}
              />
              <Box component="form" onSubmit={handleSubmit(login)}>
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{
                    background: Colors.white,

                    border: "1px solid grey",
                    outline: "none",
                    "& fieldset": {
                      border: "none",
                      borderRadius: "0px",
                    },
                    "&:hover": {
                      borderRadius: "0px",
                    },
                  }}
                  placeholder="Email"
                  type="email"
                  {...register("email", {
                    required: "Please enter your email",
                  })}

                  // error={errors?.email?.message}
                  // helperText={errors?.email?.message}
                />
                {errors.email && (
                  <Typography sx={{ ml: 2 }} variant="caption" color="error">
                    Email is required
                  </Typography>
                )}
                <TextField
                  margin="normal"
                  fullWidth
                  placeholder="Password"
                  sx={{
                    background: Colors.white,

                    "& fieldset": {
                      borderRadius: "0px",
                      border: "1px solid grey",
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "Please enter your password",
                  })}
                  // error={errors?.password?.message}
                  // helperText={errors?.password?.message}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                {errors.password && (
                  <Typography sx={{ ml: 2 }} variant="caption" color="error">
                    Password is required
                  </Typography>
                )}

                <Box
                  onClick={() => handleDialog()}
                  sx={{
                    color: Colors.black,
                    fontSize: "12px",
                    ml: 2,
                    mt: 1,
                    cursor: "pointer",
                  }}
                >
                  Forgot Password ?
                </Box>
                <SimpleDialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  title="Forgot Password"
                >
                  <Box
                    component={"form"}
                    onSubmit={handleSubmit2(handleForgotPassword)}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={12} sx={{ width: "100%" }}>
                        <InputLabel>Email </InputLabel>
                        <InputField
                          fullWidth
                          type={"email"}
                          placeholder={"Enter email address"}
                          error={errors2?.email?.message}
                          register={register2("email", {
                            required: "Please enter Email.",
                          })}
                          helperText={errors2?.email?.message}
                        />
                      </Grid>
                      <Grid item md={12} sx={{ width: "100%" }}>
                        <InputLabel>New Password </InputLabel>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          {...register2("password", {
                            required: "Please enter new password",
                          })}
                          error={errors2?.password?.message}
                          InputProps={{
                            style: { padding: "5px" },
                            endAdornment: (
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            ),
                          }}
                          helperText={errors2?.password?.message}
                          fullWidth
                          size={"small"}
                          placeholder={"New Password"}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          my: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          sx={{
                            padding: "5px 38px",
                            color: Colors.black,
                            background: Colors.primary,
                            "&:hover": {
                              background: Colors.primary,
                            },
                          }}
                        >
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </SimpleDialog>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    ":hover": {
                      color: Colors.black,
                      background: Colors.primary,
                    },
                    background: Colors.primary,
                    color: Colors.black,
                    fontWeight: "600",
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: Colors.black }} size={22} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}
