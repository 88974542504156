import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../App.css";
import { Colors } from "../../Assets/Styles/Colors";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import ShareIcon from "@mui/icons-material/Share";
import ProjectServices from "../../Api/ProjectServices/Project.index";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import UserServices from "../../Api/UserServices/User.index";
import { Images } from "../../Assets/Images/Images";
import { useNavigate } from "react-router-dom";
import Storage from "../../Utils/Storage";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import CategoryIcon from "@mui/icons-material/Category";
import { useTheme } from "@emotion/react";
import { jwtDecode } from "jwt-decode";

export default function Dashboard() {
  const [getProject, setGetProject] = useState([]);
  const [starProject, setStarProject] = useState([]);
  const [getUsers, setGetUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const { setStorageItem, getStorageItem } = Storage();
  const [allProjects, setAllProjects] = useState([]);
  const [allStarProjects, setAllStarProjects] = useState([]);

  const theme = useTheme();
  const navigate = useNavigate();
  const [user, setUser] = useState(getStorageItem("user"));
  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const company_id = userIdFromToken;

  const getProjects = async (priority) => {
    const { result, responseCode } = await ProjectServices.getProjectsWithHours(
      priority ? priority : ""
    );
    try {
      if (result) {
        setAllProjects(result); // Store all projects
        const highPriorityProjects = result.filter(
          (project) => project.priority === "high"
        );
        setAllStarProjects(highPriorityProjects); // Store all high priority projects

        // Initially display only 4 projects
        setGetProject(result.slice(0, 4));
        setStarProject(highPriorityProjects.slice(0, 4));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetUsers = async (
    newPage,
    rows,
    name,
    assignedUser,
    company_id
  ) => {
    try {
      const { data, responseCode } = await UserServices.getUser(
        newPage ? newPage : 1,
        rows ? rows : "",
        name ? name : "",
        assignedUser ? assignedUser : "",
        company_id ? company_id : ""
      );
      const filteredUser = data?.output?.users?.filter(
        (userData) => userData.role != "admin" && userData.role != "company"
      );
      setGetUsers(filteredUser.slice(0, 4));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetUsers(1, 1000, "", "", company_id);
    getProjects("");
  }, []);

  return (
    <>
      <Grid container sx={{ alignItems: "center", mt: 5, pl: 4.5 }}>
        <Grid item md={12} sx={{mb:2}}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              fontSize: "30px",
              color: Colors.primary,
              textTransform: "capitalize",
            }}
          >
            welcome {user?.name}!
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 4, pl: 4 ,pr:3}}>
        <Grid item md={4} sx={{ width: "100%" }}>
          <Box
            sx={{
              background: Colors.white,
              borderRadius: "20px",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                sx={{
                  "& .MuiPickersDay-root": {
                    fontWeight: "bold",
                  },
                  "& .MuiPickersDay-root.Mui-selected": {
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                  },
                  "& .MuiPickersDay-root:hover": {
                    backgroundColor: Colors.lightPrimary,
                  },
                  "& .MuiPickersDay-root:focus": {
                    backgroundColor: Colors.primary,
                  },
                  "& .MuiCalendarPicker-root": {
                    "& .MuiTypography-root": {
                      color: Colors.primary,
                      fontWeight: "bold",
                    },
                    "& .MuiIconButton-root": {
                      color: Colors.primary,
                    },
                  },
                  "& .MuiPickersArrowSwitcher-button .css-1vooibu-MuiSvgIcon-root":
                    {
                      background: "#FBBA1E",
                      borderRadius: "50%",
                      color: Colors.black,
                      fontSize: "15px",
                    },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              mt: 2,
              p: 2,
              background: Colors.white,
              borderRadius: "20px",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              height: "280px ",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: Colors.primary,
              }}
            >
              Project Directory
            </Typography>

            {getProject?.map((item, index) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "7px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #f5f4f3",
                    mt: 1,
                    pb: 1,
                  }}
                >
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: "7px",
                      alignItems: "center",
                    }}
                  >
                    <CategoryIcon
                      sx={{
                        fontSize: "18px",
                        color: Colors.black,
                        // transform: "rotate(20deg)",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: Colors.black,
                        ml: 1, // Add some margin to the left of the text to separate it from the icon
                      }}
                    >
                      {item?.project_name}
                    </Typography>
                  </Box>
                  <Box>
                    <AvatarGroup
                      max={4}
                      sx={{
                        ".MuiAvatar-root": {
                          width: "30px",
                          height: "30px",
                          fontSize: "12px",
                        },
                        ".MuiAvatarGroup-avatar": {
                          width: "30px",
                          height: "30px",
                          fontSize: "12px",
                        },
                      }}
                    >
                      {item?.users?.map((userImage, index) => (
                        <Tooltip title={userImage?.name}>
                          <Avatar
                            key={index}
                            alt="Remy Sharp"
                            src={
                              userImage?.user?.image
                                ? "https://crm.mangotech-api.com" +
                                  userImage?.user?.image
                                : Images.defaultImage
                            }
                          />
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  </Box>
                </Box>
              </>
            ))}

            <Box
              sx={{
                mt: 2,
                color: Colors.black,
                fontSize: "14px",
                cursor: "pointer",
                width: "100px",
                border: `1px solid ${Colors.black}`,
                borderRadius: "20px",
                padding: "5px",
                textAlign: "center",
              }}
              onClick={() => navigate("/projectList")}
            >
              View More
            </Box>
          </Box>
        </Grid>

        <Grid item md={8} sx={{width:'100%',mb:2}}>
          <Box
            sx={{
              p: 2,
              background: Colors.white,
              borderRadius: "20px",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              height: "300px",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: Colors.primary,
              }}
            >
              High Priority Projects
            </Typography>
            <Box sx={{ pt: 2 }}>
              {getProject?.map((item, index) => (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "7px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #f5f4f3",
                      mt: 1,
                      pb: 1,
                    }}
                  >
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        width: "33%",
                      }}
                    >
                      <StarIcon
                        sx={{
                          fontSize: "18px",
                          color: Colors.black,
                        }}
                      />
                      <Typography
                        sx={{
                          textOverflow: "ellipsis",
                          width: "100px",
                          overflow: "hidden",
                          fontSize: "13px",
                          color: Colors.black,
                          ml: 1,
                        }}
                      >
                        {item?.project_name} 
                      </Typography>
                    </Box>

                    <Box sx={{ width: "33%" }}>
                      <AvatarGroup
                        max={4}
                        sx={{
                          ".MuiAvatar-root": {
                            width: "30px",
                            height: "30px",
                            fontSize: "12px",
                          },
                          ".MuiAvatarGroup-avatar": {
                            width: "30px",
                            height: "30px",
                            fontSize: "12px",
                          },
                        }}
                      >
                        {item?.users?.map((userImage, index) => (
                          <Tooltip title={userImage?.name}>
                            <Avatar
                              sx={{
                                width: "30px",
                                height: "30px",
                                fontSize: "12px",
                              }}
                              alt="Remy Sharp"
                              src={
                                userImage?.user?.image
                                  ? "https://crm.mangotech-api.com" +
                                    userImage?.user?.image
                                  : Images.defaultImage
                              }
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                    </Box>

                    <Box
                      sx={{
                        color: Colors.primary,
                        width: "33%",
                        display: "flex",
                        justifyContent: "flex-end",
                        mr: 2,
                      }}
                    >
                      {item?.totalHours} hrs
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6} sx={{ width: "100%" }}>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  background: Colors.white,
                  borderRadius: "20px",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                  height: "280px ",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: Colors.primary,
                  }}
                >
                  New Users
                </Typography>

                {getUsers.length > 0 ? (
                  getUsers?.map((item, index) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "7px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: "1px solid #f5f4f3",
                          mt: 1,
                          pb: 1,
                        }}
                      >
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            gap: "7px",
                            alignItems: "center",
                          }}
                        >
                          <PersonIcon
                            sx={{
                              fontSize: "18px",
                              color: Colors.black,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: Colors.black,
                              ml: 1, // Add some margin to the left of the text to separate it from the icon
                            }}
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Avatar
                            sx={{
                              width: "30px",
                              height: "30px",
                              fontSize: "12px",
                            }}
                            alt="Remy Sharp"
                            src={
                              item?.image
                                ? "https://crm.mangotech-api.com" + item.image
                                : Images.defaultImage
                            }
                          />
                        </Box>
                      </Box>
                    </>
                  ))
                ) : (
                  <>
                    <Box sx={{ textAlign: "center", mt: 4 }}>No Data Found</Box>
                  </>
                )}
                {getUsers.length > 0 ? (
                  <Box
                    sx={{
                      mt: 2,
                      color: Colors.black,
                      fontSize: "14px",
                      cursor: "pointer",
                      width: "100px",
                      border: `1px solid ${Colors.black}`,
                      borderRadius: "20px",
                      padding: "5px",
                      textAlign: "center",
                    }}
                    onClick={() => navigate("/userList")}
                  >
                    View More
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid item md={6} sx={{ width: "100%" }}>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  background: Colors.white,
                  borderRadius: "20px",
                  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                  height: {md:"280px ",sm:'auto',xs:'auto'},
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: Colors.primary,
                  }}
                >
                  Team Directory
                </Typography>
                <Grid container spacing={2}>
                  {getUsers.length > 0 ? (
                    getUsers?.map((item, index) => (
                      <Grid item xs={12} md={6} sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            background: "#f5f6f7",
                            borderRadius: "10px",
                            padding: "6px",
                            mb: 0,
                          }}
                        >
                          <img
                            style={{
                              width: "55px",
                              height: "55px",
                              border:"1px solid grey",
                              borderRadius: "50%",
                              display: "flex",
                              margin: "0 auto",
                              justifyContent: "center",
                            }}
                            src={
                              item?.image
                                ? "https://crm.mangotech-api.com" + item?.image
                                : Images.defaultImage
                            }
                          />

                          <Box
                            sx={{
                              textAlign: "center",
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            {item?.name}
                          </Box>
                          <Box sx={{ textAlign: "center", fontSize: "13px" }}>
                            {item?.designation}
                          </Box>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        mt: 6,
                      }}
                    >
                      No Data Found
                    </Box>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
