import Form from "../View/Form/Form";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccountSetting from "../View/AccountSetting/AccountSetting";
import SettingsIcon from '@mui/icons-material/Settings';
import Progress from "../View/Progress/Progress";
import NewProgress from "../View/Progress/NewProgress";
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import Timesheet from "../View/Form/Timesheet";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeReport from "../View/Progress/TimeReport";
const PublicRoute = [
  {
    path: "/timesheet",
    component: <Timesheet /> ,
    label : "Time Sheet",
    icon:<AccessTimeIcon/>
  },
  {
    path: "/userform",
    component: <Form /> ,
    label : "Form",
    icon:<InsertDriveFileIcon/>
  },
  {
    path: "/progress",
    // component: <Progress /> ,
    component: <NewProgress /> ,
    label : "Progress",
    icon:<StackedBarChartIcon/>
  },
  {
    path: "/timereport",
    // component: <Progress /> ,
    component: <TimeReport /> ,
    label : "Attendacce Report",
    icon:<StackedBarChartIcon/>
  },
  {
    path: "/account",
    component: <AccountSetting /> ,
    label : "Account Setting",
    icon:<SettingsIcon/>
  },
  
];

export default PublicRoute;
