import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { Colors } from "../../Assets/Styles/Colors";

export default function TimeReport() {
  const currentDate = new Date();

  const last12Months = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    return {
      month: date.getMonth(),
      year: date.getFullYear(),
      label: moment(date).format("MMMM YYYY"),
    };
  }).reverse();

  const [selectedIndex, setSelectedIndex] = useState(last12Months.length - 1);

  const handleMonthYearChange = (event) => {
    setSelectedIndex(event.target.value);
  };

  const selectedMonthYear = last12Months[selectedIndex];

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push({
        date: moment(date).format("DD-MM-YYYY"),
        day: date.toLocaleString("default", { weekday: "long" }),
      });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const daysInSelectedMonth = getDaysInMonth(
    selectedMonthYear.month,
    selectedMonthYear.year
  );

  return (
    <>
      <Box sx={{ mt: 5, ml: 2 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "600", color: Colors.primary }}
        >
          My Time Report
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ mt: 3, ml: 2, mr: 2, width: "auto !important" }}>
        <Grid item md={6} sm={12} xs={12}>
          <InputLabel>Select Month and Year</InputLabel>
          <Select
            fullWidth
            value={selectedIndex}
            onChange={handleMonthYearChange}
          >
            {last12Months.map((monthYear, index) => (
              <MenuItem key={index} value={index}>
                {monthYear.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, ml: 2, mr: 2 }}>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            overflow: "auto",
            height: "400px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  color: "#fff",
                  position: "sticky",
                  top: 0,
                  backgroundColor: Colors.primary,
                  zIndex: 1,
                }}
              >
                {[ "Date", "Day", "Check-In", "Check-Out", "Total Hours", "Status", "Remarks" ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: `1px solid ${Colors.grey}`,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {daysInSelectedMonth.map((day, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? Colors.lightGray : "#f9f9f9",
                    "&:hover": { backgroundColor: Colors.hoverGray },
                  }}
                >
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    {day.date}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    {day.day}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    11:00 AM
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    12:00 PM
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    7 hour
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={{ color: Colors.danger }}>Late Arrival</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "12px 16px",
                      borderRight: `1px solid ${Colors.grey}`,
                      textAlign: "center",
                    }}
                  >
                    Ok
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
