import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Colors } from "../../Assets/Styles/Colors";
import { Images } from "../../Assets/Images/Images";
import InputField from "../../Components/InputField/InputField";
import IconButton from "@mui/material/IconButton";
import UploadIcon from "@mui/icons-material/Upload";
import { useForm } from "react-hook-form";
import SimpleDialog from "../../Components/Dialog/Dialog";
import AccountSettingServices from "../../Api/AccountSettingServices/AccountSettingServices.index";
import Storage from "../../Utils/Storage";
import { ErrorToaster, SuccessToaster } from "../../Components/Toaster/Toaster";
import UserServices from "../../Api/UserServices/User.index";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../Context/UseContext";
import ImageServices from "../../Api/ImageUploadService/ImageService.index";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ErrorHandler } from "../../Utils/ErrorHandler";
import moment from "moment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function AccountSetting() {
  const [editAccountSetting, setEditAccountSetting] = useState(false);
  const { setStorageItem, getStorageItem } = Storage();
  const { userLogin, updateProfile } = useAuth();
  const [hovered, setHovered] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [userDetail, setUserDetail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [getProfile, setGetProfile] = useState([]);
  const [user, setUser] = useState(getStorageItem("user"));
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [timings, setTimings] = useState(
    daysOfWeek.map((day) => ({ day, from: null, to: null }))
  );

  // const handleTimeChange = (index, field, value) => {
  //   setTimings((prevTimings) => {
  //     const updatedTimings = [...prevTimings];
  //     updatedTimings[index] = {
  //       ...updatedTimings[index],
  //       [field]: value ? dayjs(value).format("HH:mm ") : null,
  //     };
  //     return updatedTimings;
  //   });
  // };
  const handleTimeChange = (index, field, value) => {
    let minuteValue = value
      ? dayjs(value).hour() * 60 + dayjs(value).minute()
      : null;
    console.log(minuteValue);

    setTimings((prevTimings) => {
      const updatedTimings = [...prevTimings];
      updatedTimings[index] = {
        ...updatedTimings[index],
        [field]: minuteValue,
      };
      return updatedTimings;
    });
  };
  console.log(timings);
  // *For User
  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const userId = userIdFromToken;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,

    formState: { errors: errors2 },
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue,
  } = useForm();

  const submitForm = async (formData) => {
    console.log(formData);
    try {
      const { data, message } = await AccountSettingServices.updatePassword(
        formData
      );
      SuccessToaster(message);
      console.log(data);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
      console.log(error);
    }
  };
  const fileInputRef = useRef(null);
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    try {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);

      const response = await ImageServices.imageUpload(formData);
      setImageURL(response?.data?.imageUrl);
      updateProfile(response?.data?.imageUrl);
      handleSendImage(response?.data?.imageUrl);

      console.log(imageURL);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
    }
  };

  const handleGetUsers = async (page, limit, search, status, companyId) => {
    try {
      const { data, responseCode, message } = await UserServices.getUser(
        page,
        limit,
        search,
        status ? status : "",
        companyId ? companyId : ""
      );
      const filteredUser = data?.output?.users.filter(
        (user_id) => user_id._id == userId
      );
      console.log(filteredUser);
      setUserDetail(filteredUser);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };
  useEffect(() => {
    handleGetUsers(1, 1000, "", "", "");
  }, []);

  const handleSendImage = async (imagelink) => {
    console.log(userDetail);
    const obj = {
      email: userDetail[0]?.email,
      image: imagelink,
      name: userDetail[0]?.name,
      phone: userDetail[0]?.phone,
      projectIds: userDetail[0]?.projectIds,
      role: userDetail[0]?.role,
      _id: userDetail[0]?._id,
    };
    console.log(obj);
    try {
      const { data, responseCode, message } = await UserServices.updateUser(
        userId,
        obj
      );
      console.log(data?.output?.users);
      handleGetUsers(1, 1000, "", "", "");
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
    }
  };
  const handleUpdate = async (data) => {
    const obj = {
      ...data,
      job_timings: timings.map(({ day, from, to }) => ({
        day,
        from: from || null,
        to: to || null,
      })),
    };
    console.log(obj);
    try {
      const { response, message } = await UserServices.updateProfile(obj);
      SuccessToaster(message);
      handleGetProfile();
      console.log(response);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  const handleGetProfile = async () => {
    try {
      const { data, responseCode, message } = await UserServices.getProfile();
      setValue("name", data.user.name);
      setValue("phone", data.user.phone);
      setValue("emergency_contact", data.user.emergency_contact);
      setValue("nic", data.user.nic);
      setValue("dob", data.user.dob);
      setValue("designation", data.user.designation);
      setValue(
        "joining_date",
        moment(data.user.joining_date).format("YYYY-MM-DD")
      );
      setValue("dob", moment(data?.user?.dob).format("YYYY-MM-DD"));
      setValue("job_timings", data?.user?.job_timings);
      setValue("marital_status", data.user.marital_status);
      setValue("highest_education", data.user.highest_education);
      setValue("home_address", data.user.home_address);
      setValue("salary", data.user.salary);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };
  useEffect(() => {
    handleGetProfile();
  }, []);

  // useEffect(()=>{
  //   setValue("name", getProfile.name);
  //   setValue("phone", getProfile.phone);
  //   setValue("emergency_contact", getProfile.emergency_contact);
  //   setValue("nic", getProfile.nic);
  //   setValue("dob", getProfile.dob);
  //   setValue("designation", getProfile.designation);
  //   setValue("joining_date", getProfile.joining_date);
  //   setValue("job_timings", getProfile.job_timings);
  //   setValue("marital_status", getProfile.marital_status);
  //   setValue("highest_education", getProfile.highest_education);
  //   setValue("home_address", getProfile.home_address);
  // },[getProfile])

  return (
    <>
      <Box sx={{ mt: 5, ml: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "600", fontSize: "20px", color: Colors.primary }}
        >
          Account Setting
        </Typography>
      </Box>
      <SimpleDialog
        open={editAccountSetting}
        onClose={() => setEditAccountSetting(false)}
        title="Edit Account Setting"
      >
        <Box component={"form"} onSubmit={handleSubmit2()}>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ width: "100%" }}>
              <InputLabel>Name </InputLabel>
              <InputField
                fullWidth
                placeholder={"Enter  Name"}
                type="text"
                // value={user.name}
                error={errors2.name?.message}
                register={register2("name", {
                  required: "Please enter the name.",
                })}
              />
            </Grid>
            <Grid item md={12} sx={{ width: "100%" }}>
              <InputLabel>Email </InputLabel>
              <InputField
                fullWidth
                type={"email"}
                value={user.email}
                placeholder={"Enter email address"}
                error={errors2?.email?.message}
                register={register2("email", {
                  required: "Please enter the email.",
                  pattern: {
                    // value: emailRegex,
                    message: "Please enter a valid email.",
                  },
                })}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                sx={{
                  padding: "5px 38px",
                  color: Colors.white,
                  background: Colors.primary,
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>

      <Box sx={{ mt: 2 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item md={4} xs={12} sx={{ padding: { md: "12px", xs: "6px" } }}>
            <Box
              sx={{
                background: Colors.white,
                borderRadius: "10px",
                padding: "38px 15px",
                boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: 90,
                  height: 90,
                  margin: "0 auto",
                  mt: 2,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <Box
                  component="img"
                  src={
                    userDetail[0]?.image
                      ? "https://crm.mangotech-api.com" + userDetail[0]?.image
                      : Images.defaultImage
                  }
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    cursor: "pointer",
                    objectFit: "contain",
                    border: "1px solid grey",
                  }}
                  onClick={(e) => {
                    handleImageClick(e);
                  }}
                />
                {hovered && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      padding: "5px 15px",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      display: "block",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                      },
                    }}
                    onClick={(e) => {
                      handleImageClick(e);
                    }}
                  >
                    <UploadIcon />
                    <Box sx={{ fontSize: "12px" }}>Upload Image</Box>
                  </IconButton>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </Box>

              <Box
                sx={{
                  mt: 3,

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box component={"span"} sx={{ fontWeight: "600" }}>
                  Name:{" "}
                </Box>
                <Box component={"span"} sx={{ fontWeight: "400" }}>
                  {userDetail[0]?.name}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 3,

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box component={"span"} sx={{ fontWeight: "600" }}>
                  Email:{" "}
                </Box>
                <Box component={"span"} sx={{ fontWeight: "400" }}>
                  {userDetail[0]?.email}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 3,

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box component={"span"} sx={{ fontWeight: "600" }}>
                  Phone Number:{" "}
                </Box>
                <Box component={"span"} sx={{ fontWeight: "400" }}>
                  {userDetail[0]?.phone}
                </Box>
              </Box>
              {userDetail[0]?.role === "user" && (
                <Box
                  sx={{
                    mt: 3,

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box component={"span"} sx={{ fontWeight: "600" }}>
                    Designation:{" "}
                  </Box>
                  <Box component={"span"} sx={{ fontWeight: "400" }}>
                    {userDetail[0]?.designation
                      ? userDetail[0]?.designation
                      : "NIL"}
                  </Box>
                </Box>
              )}
              {/* <Box
                sx={{
                  mt: 3,

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Box component={"span"} sx={{ fontWeight: "600" }}>Role: </Box>
                <Box component={"span"} sx={{ fontWeight: "400" }}>
                    {userDetail[0]?.role ? userDetail[0]?.role :"NIL"}
                </Box>
              </Box> */}
            </Box>
          </Grid>

          <Grid item md={8} xs={12} sx={{ padding: { md: "12px", xs: "6px" } }}>
            <Box
              sx={{
                background: Colors.white,
                borderRadius: "10px",
                padding: "38px 15px",
                boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "600", color: Colors.primary }}
              >
                Change Password
              </Typography>
              <form onSubmit={handleSubmit(submitForm)}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item md={6} sx={{ width: "100%" }}>
                    <Box sx={{ fontWeight: "500", fontSize: "14px", mb: 1 }}>
                      Old Password
                    </Box>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      {...register("old_password", {
                        required: "Please enter old password",
                      })}
                      error={errors?.old_password?.message}
                      InputProps={{
                        style: { padding: "5px" },
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                      helperText={errors?.old_password?.message}
                      fullWidth
                      size={"small"}
                      placeholder={"Old Password"}
                    />
                  </Grid>
                  <Grid item md={6} sx={{ width: "100%" }}>
                    <Box sx={{ fontWeight: "500", fontSize: "14px", mb: 1 }}>
                      New Password
                    </Box>
                    <TextField
                      type={showPassword1 ? "text" : "password"}
                      {...register("new_password", {
                        required: "Please enter new password",
                      })}
                      error={errors?.new_password?.message}
                      InputProps={{
                        style: { padding: "5px" },
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword1(!showPassword1)}
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        ),
                      }}
                      helperText={errors?.new_password?.message}
                      fullWidth
                      size={"small"}
                      placeholder={"New Password"}
                    />
                  </Grid>

                  <Grid item md={12}>
                    <Button
                      type="submit"
                      sx={{
                        fontWeight: "500",
                        textAlign: "center",
                        borderRadius: "5px",
                        padding: "6px 30px",
                        cursor: "pointer",
                        fontSize: "14px",
                        mb: 1,
                        background: Colors.primary,
                        color: Colors.black,
                        "&:hover": {
                          background: Colors.primary,
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>

          <form onSubmit={handleSubmit3(handleUpdate)}>
            <Grid item xs={12} sx={{ padding: { md: "12px", xs: "6px" } }}>
              <Box
                sx={{
                  background: Colors.white,
                  borderRadius: "10px",
                  padding: "38px 15px",
                  boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                      Edit Profile
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Name</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("name")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Phone</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("phone")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Emergency Contact</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("emergency_contact")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>National Identity Card</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("nic")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Date of Birth</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register3("dob")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Designation</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("designation")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Joining Date</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register3("joining_date")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Marital Status</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("marital_status")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Highest Education</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      {...register3("highest_education")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Salary</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register3("salary")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <InputLabel shrink>Home Address</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      // multiline
                      // rows={2}
                      {...register3("home_address")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel shrink>Job Timings</InputLabel>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="job timings table"
                      >
                        <TableHead
                          sx={{ position: "sticky", top: 0, zIndex: 4 }}
                        >
                          <TableRow sx={{ backgroundColor: Colors.primary }}>
                            <TableCell
                              sx={{ fontWeight: "600", textAlign: "center" }}
                            >
                              Days
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "600", textAlign: "center" }}
                            >
                              From
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "600", textAlign: "center" }}
                            >
                              To
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {/* <TableBody>
  {timings.map((row, index) => (
    <TableRow key={index}>
      <TableCell sx={{ textAlign: "center" }}>{row.day}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="From"
            value={row.from ? dayjs(row.from, "HH:mm") : null} // Convert back to dayjs
            onChange={(newValue) => handleTimeChange(index, "from", newValue)}
            renderInput={(params) => <input {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="To"
            value={row.to ? dayjs(row.to, "HH:mm") : null} // Convert back to dayjs
            onChange={(newValue) => handleTimeChange(index, "to", newValue)}
            renderInput={(params) => <input {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
    </TableRow>
  ))}
</TableBody> */}
                        <TableBody>
                          {timings.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ textAlign: "center" }}>
                                {row.day}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    label="From"
                                    value={row.from ? dayjs().startOf("day").add(row.from, "minute"): null}
                                    onChange={(newValue) =>
                                      handleTimeChange(index, "from", newValue)
                                    }
                                    renderInput={(params) => (
                                      <input {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    label="To"
                                    value={row.to ? dayjs().startOf("day").add(row.to, "minute"): null}
                                    onChange={(newValue) =>
                                      handleTimeChange(index, "to", newValue)
                                    }
                                    renderInput={(params) => (
                                      <input {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      sx={{
                        padding: "5px 38px",
                        color: Colors.white,
                        background: Colors.primary,
                        ":hover": {
                          background: Colors.primary,
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </form>
        </Grid>
      </Box>
    </>
  );
}
