import { UserRoutes } from "./User.route";
import { post, get, put,patch, deleted } from "../index";


const UserServices = {
  createUser: async (obj) => {
    let result = await post(UserRoutes.createUser, obj);
    return result;
  },
  getUser: async (page , limit,search,status,companyId) => {
    let result = get(UserRoutes.getUser +`?page=${page}&limit=${limit}&search=${search}&status=${status}`);
    return result;
  },
  updateUser: async (id ,obj) => {
    let result = put(UserRoutes.updateUser + `/${id}`,obj);
    console.log(id)
    return result;
  },
  updateProfile: async (obj) => {
    let result = patch(UserRoutes.updateProfile ,obj);

    return result;
  },
  getProfile: async () => {
    let result = get(UserRoutes.getProfile);

    return result;
  },
  deleteUser: async (id) => {
    let result = deleted(UserRoutes.deleteUser +`/${id}`);

    return result;
  },
};

export default UserServices;
