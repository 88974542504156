import {
  Box,
  Paper,
  Grid,
  IconButton,
  Table,
  Button,
  Collapse,
  FormControl,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
  InputLabel,
  Tooltip,
  MenuItem,
  Select,
  Autocomplete,
  ListItem,
  ListItemText,
  Checkbox,
  List,
} from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import { Colors } from "../../../Assets/Styles/Colors";
import {
  FilterAlt,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import InputField from "../../../Components/InputField/InputField";
import ProjectServices from "../../../Api/ProjectServices/Project.index";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import moment from "moment";
import Loader from "../../../Components/Loader/Loader";
import { useTheme } from "@emotion/react";
import SimpleDialog from "../../../Components/Dialog/Dialog";
import MultiSelectAutocomplete from "../../../Components/MultSelectInput/MultiSelectInput";
import UserServices from "../../../Api/UserServices/User.index";
import { Controller, Form, useForm } from "react-hook-form";
import { ErrorHandler } from "../../../Utils/ErrorHandler";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../Components/Toaster/Toaster";
import Storage from "../../../Utils/Storage";
import { jwtDecode } from "jwt-decode";
import FileServices from "../../../Api/FileUploadServices/FileUpload.index";

function DataFilter({ sortData, page, rows }) {
  const [filterCollapse, setFilterCollapse] = useState(true);

  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [statuses, setStatuses] = useState("");
  const [priority, setPriority] = useState("");

  const filterData = () => {
    sortData(page, rows, name, priority, statuses, selectedId);
  };
  const clearData = () => {
    sortData("");
    setSelectedId("");
    setName("");
    setStatuses("");
    setPriority("");
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Box
        component={Paper}
        sx={{ bgcolor: Colors.white, p: 2, borderRadius: "12px", mt: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: Colors.primary }}
          >
            Filters
          </Typography>
          <IconButton
            onClick={() => setFilterCollapse(!filterCollapse)}
            sx={{ bgcolor: Colors.primaryLight, color: Colors.primary }}
          >
            <FilterAlt />
          </IconButton>
        </Box>

        <Collapse in={filterCollapse}>
          <Grid container spacing={2} justifyContent="space-between">
            <Fragment>
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Name</InputLabel>
                <InputField
                  fullWidth
                  value={name}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ DataFilter ~ e.target.value:",
                      e.target.value
                    );
                    setName(e.target.value);
                  }}
                  placeholder={"Search"}
                />
              </Grid>

              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  fullWidth
                  value={statuses}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ DataFilter ~ e.target.value:",
                      e.target.value
                    );
                    setStatuses(e.target.value);
                  }}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">InActive</MenuItem>
                </Select>
              </Grid>
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Priority</InputLabel>
                <Select
                  fullWidth
                  value={priority}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ DataFilter ~ e.target.value:",
                      e.target.value
                    );
                    setPriority(e.target.value);
                  }}
                >
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                </Select>
              </Grid>
            </Fragment>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                onClick={clearData}
                // variant="outlined"
                sx={{
                  color: Colors.black,
                  mx: 1,
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                }}
              >
                Clear
              </Button>
              <Button
                onClick={filterData}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  color: Colors.white,
                  background: Colors.primary,
                  "&:hover": {
                    background: Colors.primary,
                  },
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
}
function TablePaginationActions(props) {
  const theme = useTheme;
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}
export default function CustomerDetails() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [priority, setPriority] = useState("");
  const [statuses, setStatuses] = useState("");
  const [getProject, setGetProject] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [names, setNames] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [personName, setPersonName] = useState([]);
  const [selectStatus, setSelectStatus] = useState("");
  const [selectPriority, setSelectPriority] = useState("");
  const [selectManager, setSelectManager] = useState("");
  const [selectSupervisor, setSelectSupervisor] = useState("");
  const [checkedUserIds, setCheckedUserIds] = useState([]);
  const [fileURL, setFileURL] = useState("");
  const { setStorageItem, getStorageItem } = Storage();
  const [user, setUser] = useState(getStorageItem("user"));

  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const company_id = userIdFromToken;

  // const [endDate, setEndDate] = useState("");
  // const [startDate, setStartDate] = useState("");

  const tableHead = [
    "Sr",
    "Name",
    "Description",
    "Assigned User",
    "Assigned Manager",
    "Assigned Supervisor",
    "Start Date",
    "End Date",
    "Status",
    "Priority",
    "File",
    "Client Details",
    "Comments",
    "Action",
  ];

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();

  let sleep = () => new Promise((r) => setTimeout(r, 2000));
  const sortByStatus = (page, rows, name, priority, statuses, serviceId) => {
    setName(name);
    setPriority(priority);
    setStatuses(statuses);
    setSelectedId(serviceId);
    setLimit(10);
    setPage(0);
    getProjects(
      page,
      rows,
      name ? name : "",
      serviceId ? serviceId : null,
      priority ? priority : "",
      statuses ? statuses : ""
    );
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    getProjects(newPage + 1, limit, name, priority, statuses, selectedId);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(Math.floor(event.target.value));
    setPage(0);
    getProjects(1, event.target.value, name, priority, statuses, selectedId);
  };

  //project list
  const getProjects = async (
    newPage,
    rows,
    name,
    assignedUser,
    priority,
    statuses
  ) => {
    setLoading(true);
    const { data, responseCode } = await ProjectServices.getProject(
      newPage ? newPage : 1,
      rows ? rows : "",
      name ? name : "",
      assignedUser ? assignedUser : "",
      priority ? priority : "",
      statuses ? statuses : ""
    );
    try {
      await sleep();
      setGetProject(data);
      setCount(data?.output?.totalProjects);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjects(page + 1, limit, name, priority, statuses, selectedId);
  }, []);

  ///users list in dropdown
  const handleGetUsers = async (page, limit, search, status, companyId) => {
    try {
      const { data, responseCode, message } = await UserServices.getUser(
        page,
        limit,
        search,
        status ? status : "",
        companyId ? companyId : ""
      );
      const filteredName = data?.output?.users.filter(
        (email) => email.role !== "admin" && email.role !== "company"
      );
      setNames(filteredName);
      setTotalCount(data?.output?.totalUsers);
      console.log(data);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };
  useEffect(() => {
    handleGetUsers(1, 1000, "", "", company_id);
  }, []);

  // console.log(personName);
  const handleCheck = (event, userId) => {
    setCheckedUserIds((prevCheckedUserIds) => {
      const isChecked = event.target.checked;
      const newCheckedUserIds = isChecked
        ? [...prevCheckedUserIds, userId]
        : prevCheckedUserIds.filter((id) => id !== userId);
      console.log(newCheckedUserIds);
      return newCheckedUserIds;
    });
  };

  const handleDeleteProject = async (id) => {
    console.log(id?._id);
    try {
      // // Add your delete logic here
      const { data, responseCode, message } =
        await ProjectServices.deleteProject(id?._id);
      setOpenDialog3(false);
      SuccessToaster(message);
      getProjects(page + 1, limit, name, priority, statuses, selectedId);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
    }
  };
  const handleDelete = async () => {
    try {
      let obj = {
        assignedUsers: checkedUserIds.map((id) => ({ user: id })),
      };
      console.log(obj);
      const data = await ProjectServices.deleteAssignedUser(
        selectedUser._id,
        obj
      );
      console.log(data);
      setOpenDialog2(false);

      getProjects(page + 1, limit, name, priority, statuses, selectedId);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
    }
  };
  console.log(selectedUser);

  let desiredArray = selectedUser
    ? selectedUser.assignedUsers
        ?.map((userId) => ({
          user: userId?.user?._id,
        }))
        .filter((userObj) => userObj.user)
    : [];
  console.log("Desired Array:", desiredArray);

  let selectedArray = personName
    ? personName
        .map((userId) => ({
          user: userId?._id,
        }))
        .filter((userObj) => userObj.user)
    : [];
  console.log("Selected Array:", selectedArray);

  let seen = new Set();
  let mergedArray = [...desiredArray, ...selectedArray].filter((userObj) => {
    if (seen.has(userObj.user)) {
      return false;
    } else {
      seen.add(userObj.user);
      return true;
    }
  });
  console.log("Merged Array:", mergedArray);

  const handleFileUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await FileServices.FileUpload(formData);
      console.log(response?.data?.fileUrl);
      setFileURL(response?.data?.fileUrl);
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  const handleUpdateProject = async (formData) => {
    try {
      let obj = {
        name: formData?.name,
        description: formData?.description,
        deadline: formData?.endDate,
        comments: formData?.comments,
        // assignedUsers: personName?.map((userId) => ({
        //   user: userId?._id,
        // })),
        assignedUsers: mergedArray,
        //
        assignedManager: formData?.assignedManager,
        assignedSupervisor: formData?.assignedSupervisor,
        endDate: formData?.endDate,
        status: formData?.status,
        priority: formData?.priority,
        file: fileURL,
        clientDetails: formData?.clientDetails,
      };

      console.log(obj);

      const { data, responseCode, message } =
        await ProjectServices.updateProject(obj, selectedUser._id);
      setOpenDialog(false);
      setLoading(true);
      await sleep();
      console.log(data);
      SuccessToaster(message);
      getProjects(page + 1, limit, name, priority, statuses, selectedId);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
      console.error("Error while fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialog = (user) => {
    console.log(user);
    setOpenDialog(true);
    setSelectedUser(user);
  };
  const handleDialog2 = (user) => {
    console.log(user);
    setOpenDialog2(true);
    setCheckedUserIds([]);
    setSelectedUser(user);
  };
  const handleDialog3 = (user) => {
    setOpenDialog3(true);
    console.log(user);
    setSelectedUser(user);
  };

  useEffect(() => {
    if (selectedUser) {
      setValue("name", selectedUser?.name);
      setValue("description", selectedUser?.description);
      setValue(
        "startDate",
        moment(selectedUser.createdAt).format("YYYY-MM-DD")
      );
      setValue("endDate", moment(selectedUser.endDate).format("YYYY-MM-DD"));
      setValue("file", selectedUser?.file);
      setValue("clientDetails", selectedUser?.clientDetails);
      setValue("comments", selectedUser?.comments);
      setValue("status", selectedUser?.status);
      setValue("priority", selectedUser?.priority);
      setValue("assignedManager", selectedUser?.assignedManager);
      setValue("assignedSupervisor", selectedUser?.assignedSupervisor);
      setSelectStatus(selectedUser?.status);
      setSelectPriority(selectedUser?.priority);
      setSelectManager(
        names.find((option) => option?.name === selectedUser?.assignedManager)
      );
      setSelectSupervisor(
        names.find(
          (option) => option?.name === selectedUser?.assignedSupervisor
        )
      );
    }
  }, [openDialog, selectedUser, setValue.names]);

  const handleStatusChange = (event) => {
    setSelectStatus(event.target.value);
    setValue("status", event.target.value);
  };
  const handlePriorityChange = (event) => {
    setSelectPriority(event.target.value);
    setValue("priority", event.target.value);
  };

  return (
    <>
      <Box sx={{ mt: 4, ml: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "600", color: Colors.primary }}
        >
          Project List
        </Typography>
      </Box>

      {/* ////filter */}
      <DataFilter
        sortData={(page, rows, search, priority, statuses, serviceId) =>
          sortByStatus(page, rows, search, priority, statuses, serviceId)
        }
        page={page}
        rows={limit}
      />

      {/* //Dialog */}
      <SimpleDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        title="Edit Project"
        selectedUser={selectedUser}
      >
        <Box component={"form"} onSubmit={handleSubmit(handleUpdateProject)}>
          <Grid sx={{ mt: 2 }} container spacing={2} alignItems="center">
            {/* Name */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Project Name</InputLabel>
              <TextField type="text" fullWidth {...register("name")} />
              {errors.name && (
                <Typography variant="caption" color="error">
                  Name is required
                </Typography>
              )}
            </Grid>
            {/* Email */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Description</InputLabel>
              <TextField type="text" fullWidth {...register("description")} />
            </Grid>
            {/* Assigned USers */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Assigned Users</InputLabel>

              <MultiSelectAutocomplete
                names={names}
                onClick={handleGetUsers}
                register={register("assignedUser")}
                sx={{ width: "100%" }}
                personName={personName}
                setPersonName={setPersonName}
                selectedUser={selectedUser}
              />
            </Grid>
            {/* Start Date */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Start Date</InputLabel>
              <TextField type="date" fullWidth {...register("startDate")} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Assign Manager</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register(
                  "assignedManager"
                  //  { required: true }
                )}
              />
              {/* {errors.assignedManager && (
                <Typography variant="caption" color="error">
                  Assigned Manager is required
                </Typography>
              )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Assign Supervisor</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register(
                  "assignedSupervisor"
                  //  { required: true }
                )}
              />
              {/* {errors.assignedSupervisor && (
                <Typography variant="caption" color="error">
                  Assigned Supervisor is required
                </Typography>
              )} */}
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Assign Manager</InputLabel>
              <Controller
                name="assignedManager"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={names}
                    getOptionLabel={(option) => option.name || ""}
                    // isOptionEqualToValue={(option, value) =>
                    //   option?.id === value?.id
                    // }
                    value={selectManager}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...register("assignedManager")}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Assign Supervisor</InputLabel>
              <Controller
                name="assignedSupervisor"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={names}
                    getOptionLabel={(option) => option.name || ""}
                    // isOptionEqualToValue={(option, value) =>
                    //   option?.id === value?.id
                    // }
                    value={selectSupervisor}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...register("assignedSupervisor")}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
              />
            </Grid> */}
            {/* Status  */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Status</InputLabel>
              <TextField
                fullWidth
                select
                {...register("status", { required: true })}
                value={selectStatus}
                onChange={handleStatusChange}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">InActive</MenuItem>
              </TextField>
            </Grid>
            {/* Priority  */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Priority</InputLabel>
              <TextField
                fullWidth
                select
                {...register("priority", { required: true })}
                value={selectPriority}
                onChange={handlePriorityChange}
              >
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
              </TextField>
            </Grid>
            {/* End Date */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>End Date</InputLabel>
              <TextField
                type="date"
                fullWidth
                {...register("endDate", { required: true })}
              />
            </Grid>
            {/* File */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>File</InputLabel>
              <TextField
                type="file"
                fullWidth
                {...register("file")}
                onChange={(e) => {
                  handleFileUpload(e);
                }}
              />
            </Grid>
            {/* Client Details */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Client Details</InputLabel>
              <TextField type="text" fullWidth {...register("clientDetails")} />
            </Grid>
            {/* Comments */}
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel>Comments (optional)</InputLabel>
              <TextField type="text" fullWidth {...register("comments")} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                // onClick={AssignProject}
                type="submit"
                sx={{
                  mt: 2,
                  width: "100px",
                  cursor: "pointer",
                  background: Colors.primary,
                  color: Colors.white,
                  padding: "8px 25px",
                  "&:hover": {
                    background: Colors.primary,
                  },
                }}
              >
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>
      {/* DIalog 2 */}
      <SimpleDialog
        open={openDialog2}
        onClose={() => {
          setOpenDialog2(false);
        }}
        title="Delete Assigned Users"
        selectedUser={selectedUser}
      >
        <Box>
          <Grid container>
            <Grid item md={12} sx={{ width: "100%" }}>
              <List>
                {selectedUser?.assignedUsers?.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Checkbox
                      onChange={(event) => handleCheck(event, item?.user?._id)}
                      sx={{ color: Colors.primary }}
                    />
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {item?.user?.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                onClick={handleDelete}
                color="secondary"
                variant="contained"
                sx={{ mt: 2 }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SimpleDialog>
      {/* DIalog 3*/}
      <SimpleDialog
        open={openDialog3}
        onClose={() => {
          setOpenDialog3(false);
        }}
        selectedUser={selectedUser}
        title=" Are you sure you want to delete this project?"
      >
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 5 }}>
            <Button
              onClick={() => setOpenDialog3(false)}
              color="secondary"
              variant="contained"
              sx={{ mr: 1, fontWeight: "560" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteProject(selectedUser)}
              color="secondary"
              variant="contained"
              sx={{ mr: 1, fontWeight: "560" }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </SimpleDialog>

      {loading ? (
        <Loader width="40px" height="40px" />
      ) : (
        <Box sx={{ mt: 3, ml: 2 ,mr:2}}>
          <TableContainer
            component={Paper}
            sx={{ oveflow: "scroll", height: "400px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ position: "sticky", top: "0px", left: "1px " }}>
                <TableRow sx={{ backgroundColor: Colors.primary }}>
                  {tableHead.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{ fontWeight: "600", color: Colors.black }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getProject?.output?.projects.length > 0 ? (
                  getProject?.output?.projects?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "rgb(239 240 246)" : " ",
                      }}
                    >
                      <>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Typography sx={{ width: "150px", fontSize: "15px" }}>
                            {item.name != "" || null ? item.name : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ width: "300px", fontSize: "15px" }}>
                            {item.description != "" || null
                              ? item.description
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={() => handleDialog2(item)}>
                          <Typography
                            sx={{
                              width: "300px",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                          >
                            {item.assignedUsers.length > 0
                              ? item.assignedUsers
                                  .map((item) => item?.user?.name)
                                  .join(", ")
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {item?.assignedManager?.length > 0
                            ? item?.assignedManager
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {item.assignedSupervisor?.length > 0
                            ? item?.assignedSupervisor
                            : "-"}
                        </TableCell>

                        <TableCell>
                          <Typography sx={{ width: "85px", fontSize: "13px" }}>
                            {item.startDate != "" || null
                              ? moment(item?.createdAt).format("DD-MM-YYYY")
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ width: "85px", fontSize: "13px" }}>
                            {item.endDate
                              ? moment(item?.endDate).format("DD-MM-YYYY")
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {item.status != "" || null ? item.status : "-"}
                        </TableCell>
                        <TableCell>
                          {item.priority != "" || null ? item.priority : "-"}
                        </TableCell>
                        <TableCell>
                          {item.file ? (
                            <InsertDriveFileIcon
                              onClick={() => {
                                const link = document.createElement("a");
                                link.href =
                                  "https://crm.mangotech-api.com" + item.file;
                                link.setAttribute("download", "");
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                              sx={{ color: Colors.primary, cursor: "pointer" }}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          {item.clientDetails ? item.clientDetails : "-"}
                        </TableCell>
                        <TableCell>
                          {item.comments ? item.comments : "-"}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            <Tooltip title="Edit Project">
                              <Box
                                onClick={() => handleDialog(item)}
                                sx={{
                                  cursor: "pointer",
                                  color: Colors.black,
                                }}
                              >
                                <EditIcon />
                              </Box>
                            </Tooltip>
                            <Tooltip title="Delete Project">
                              <Box
                                onClick={() => handleDialog3(item)}
                                sx={{ cursor: "pointer", color: Colors.black }}
                              >
                                <DeleteIcon />
                              </Box>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={tableHead.length}>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "280px", // Adjust height as needed
                          fontSize: "25px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        No data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Table component={Paper} sx={{ borderRadius: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: Colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={count === undefined ? 0 : +count}
                rowsPerPage={limit}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Box>
        </Box>
      )}
    </>
  );
}
