import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";
import { Images } from "../../Assets/Images/Images";
import { ErrorToaster, SuccessToaster } from "../../Components/Toaster/Toaster";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { ErrorHandler } from "../../Utils/ErrorHandler";
import { useAuth } from "../../Context/UseContext";

const AnalogClock = () => {
  const [time, setTime] = useState(new Date());
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [checkInLocation, setCheckInLocation] = useState(null);
  const [checkOutLocation, setCheckOutLocation] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState("");
  const { user, updateProfile } = useAuth();

  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const localTime = new Date(time.toLocaleString("en-US"));
  const formattedDate = localTime.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = localTime.toLocaleTimeString("en-US");

  const handleCheckIn = async () => {
    if (!checkInTime) {
      getLocation(async (location) => {
        if (location) {
          // const utcCheckInTime = moment.utc(time).format();
          // const utcCheckInTime = moment(time).format();
          const utcCheckInTime = formattedTime;

          setCheckInTime(utcCheckInTime);
          setCheckInLocation(location);
          const obj = {
            employee_id: userIdFromToken,
            status: "checkin",
            latitude: location.latitude.toString(),
            longitude: location.longitude.toString(),
            timestamp: utcCheckInTime,
          };
          console.log(obj);

          try {
            // const { message } = await JobTimeServices.setJobTime(obj);
            // SuccessToaster(message);
           
          } catch (error) {
            ErrorToaster(error || "Error during check-in");
          }
        } else {
          ErrorToaster(
            "Location not available. Please enable location services."
          );
        }
      });
    }
  };

  const handleCheckOut = async () => {
    if (!checkOutTime) {
      getLocation(async (location) => {
        if (location) {
          const utcCheckOutTime = formattedTime;
          // const utcCheckOutTime = moment.utc(time).format();
          setCheckOutTime(utcCheckOutTime);
          setCheckOutLocation(location);
          const obj = {
            employee_id: userIdFromToken,

            status: "checkout",
            latitude: location.latitude.toString(),
            longitude: location.longitude.toString(),
            timestamp: utcCheckOutTime,
          };

          try {
            // const { message } = await JobTimeServices.setJobTime(obj);
            // SuccessToaster(message);
         
          } catch (error) {
            ErrorToaster(error || "Error during check-out");
          }
        } else {
          ErrorToaster(
            "Location not available. Please enable location services."
          );
        }
      });
    }
  };

  const getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          callback({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
          callback(null);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      callback(null);
    }
  };
 

  return (
    <>
   
    <Grid
      container
      alignItems="center"
      sx={{ width: { xs: "87%", sm: "100%" }, margin: "0 auto" }}
    >
    
      <Grid
        item
        md={
          checkInTime ||
          employeeDetail.currentStatus === "checkout" ||
          employeeDetail?.time_sheet?.checkin !== ""
            ? 7
            : 12
        }
        sm={12}
        xs={12}
      >
        
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 11 }}
        >
          
          <Box
            sx={{
              width: { xs: "300px", sm: "80%" },
              bgcolor: Colors.dashboardBgColor,
              borderRadius: "20px",
              boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
              p: 3,
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              align="center"
              marginBottom="10px"
              sx={{ color: Colors.primary, fontFamily: "Poppins" }}
            >
              {formattedDate} <br />
              {formattedTime}
            </Typography>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={6}
              mb={2}
            >
              <svg
                width="200"
                height="200"
                viewBox="0 0 100 100"
                className="clock-face"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="48"
                  stroke="#e0e0e0"
                  strokeWidth="2"
                  fill="none"
                />
                <line
                  x1="50"
                  y1="50"
                  x2="50"
                  y2="30"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  transform={`rotate(${
                    localTime.getHours() * 30 +
                    localTime.getMinutes() / 2
                  } 50 50)`} // Hour hand
                />
                <line
                  x1="50"
                  y1="50"
                  x2="50"
                  y2="20"
                  stroke="#424242"
                  strokeWidth="2"
                  strokeLinecap="round"
                  transform={`rotate(${localTime.getMinutes() * 6} 50 50)`} // Minute hand
                />
                <line
                  x1="50"
                  y1="50"
                  x2="50"
                  y2="15"
                  stroke={Colors.primary}
                  strokeWidth="1"
                  strokeLinecap="round"
                  transform={`rotate(${localTime.getSeconds() * 6} 50 50)`} // Second hand
                />
              </svg>
            </Box>

            <Box
              align="center"
              onClick={() => {
                // employeeDetail.currentStatus === "No activity" && employeeDetail?.job_id ? handleCheckIn() : employeeDetail.currentStatus === "checkin" && handleCheckOut();
                handleCheckIn();
              }}
              sx={{
                mt: 3,
                p: "7px",
                background: Colors.primary,
                color: Colors.white,
                borderRadius: "13px",
                cursor: "pointer",
              }}
            >
              {/* {employeeDetail.currentStatus === "checkout" || employeeDetail.currentStatus === "" ? ( */}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: Colors.primary,
                  ":hover": {
                    backgroundColor: Colors.primary,
                    boxShadow: "none",
                  },
                  color: Colors.white,
                  boxShadow: "none",
                  fontFamily: "Poppins",
                  "&.Mui-disabled": {
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                Check In
              </Button>
              {/* ) : employeeDetail.currentStatus === "checkin" ? ( */}
              {/* <Button
                  variant="contained"
                  sx={{
                    backgroundColor: Colors.primary,
                    ":hover": {
                      backgroundColor: Colors.primary,
                      boxShadow: "none",
                    },
                    color: Colors.white,
                    boxShadow: "none",
                    fontFamily: "Poppins",
                  }}
                >
                  Check Out
                </Button> */}
              {/* ) : (
                <Typography sx={{ p: "6px" }}>Checked Out</Typography>
              )} */}
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* Display Check In/Out Times */}
      <Grid
        item
        md={5}  sm={12} xs={12} sx={{ mt: 4 }}
        // md={checkInTime || (employeeDetail.currentStatus === "checkout" && checkInTime) || employeeDetail?.time_sheet?.checkin !== "" ? 5 : 12} sm={12} xs={12} sx={{ mt: 4 }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ my: { md: 0, xs: 3 } }}
        >
          <Grid
            container
            spacing={2}
            sx={{ width: { md: "87%", sm: "87%", xs: "351px" } }}
          >
            {/* {employeeDetail?.time_sheet?.length > 0 ? ( */}
            <Grid container spacing={2}>
              {/* {employeeDetail?.time_sheet?.[employeeDetail?.time_sheet?.length - 1] && (
                  <> */}
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    bgcolor: Colors.dashboardBgColor,
                    borderRadius: "20px",
                    boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <img
                        width="60px"
                        src={Images.jobPendingDashboard}
                        alt="Job Pending"
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        variant={"body2"}
                        sx={{ color: Colors.black, fontWeight: 600 }}
                      >
                        Check In Time
                      </Typography>
                      <Typography
                        sx={{ fontSize: "23px", fontWeight: "bold", mt: "3px" }}
                      >
                        12:00 AM
                        {/* {moment.utc(employeeDetail.time_sheet[employeeDetail.time_sheet.length - 1].checkin).format("HH:mm:ss A")} */}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* {employeeDetail.time_sheet[employeeDetail.time_sheet.length - 1].checkout && ( */}
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    bgcolor: Colors.dashboardBgColor,
                    borderRadius: "20px",
                    boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <img
                        width="60px"
                        src={Images.jobPendingDashboard}
                        alt="Job Pending"
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        variant={"body2"}
                        sx={{ color: Colors.black, fontWeight: 600 }}
                      >
                        Check Out Time
                      </Typography>
                      <Typography
                        sx={{ fontSize: "23px", fontWeight: "bold", mt: "3px" }}
                      >
                        12:00 AM
                        {/* {moment.utc(employeeDetail.time_sheet[employeeDetail.time_sheet.length - 1].checkout).format("hh:mm:ss A")} */}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/* )} */}
              {/* </> */}
              {/* )} */}
            </Grid>
            {/* ) : (
              <Typography variant="body2" sx={{ color: Colors.gray }}></Typography>
            )} */}
          </Grid>
        </Box>
      </Grid>
    </Grid>
    </>
  );
};

export default AnalogClock;
