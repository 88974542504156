import {
  Box,
  Paper,
  Grid,
  IconButton,
  Table,
  Button,
  MenuItem,
  Collapse,
  FormControl,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TextField,
  InputLabel,
  TablePagination,
  Tooltip,
  Select,
} from "@mui/material";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { Colors } from "../../../Assets/Styles/Colors";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  FilterAlt,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import InputField from "../../../Components/InputField/InputField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserServices from "../../../Api/UserServices/User.index";
import SimpleDialog from "../../../Components/Dialog/Dialog";
import { useForm } from "react-hook-form";
import Loader from "../../../Components/Loader/Loader";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../Components/Toaster/Toaster";
import { useTheme } from "@emotion/react";
import MultiSelectAutocomplete from "../../../Components/MultSelectInput/MultiSelectInput";
import ProjectServices from "../../../Api/ProjectServices/Project.index";
import { ErrorHandler } from "../../../Utils/ErrorHandler";
import { Images } from "../../../Assets/Images/Images";
import { jwtDecode } from "jwt-decode";
import Storage from "../../../Utils/Storage";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

function DataFilter({ sortData, page, rows, company_id }) {
  const [filterCollapse, setFilterCollapse] = useState(true);
  

  const [selectedId, setSelectedId] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  const filterData = () => {
    sortData(page, rows, search, status, company_id, selectedId);
    console.log(selectedId);
    console.log("🚀 ~ filterData ~ search:", search);
  };

  const clearData = () => {
    sortData("");
    setSelectedId("");
    setSearch("");
    setStatus("");
    console.log("🚀  ~ clear:", status);
  };

  return (
    <Box sx={{ mx: 2 }}>
      <Box
        component={Paper}
        sx={{ bgcolor: Colors.white, p: 2, borderRadius: "12px", mt: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ color: Colors.primary }}
          >
            Filters
          </Typography>
          <IconButton
            onClick={() => setFilterCollapse(!filterCollapse)}
            sx={{ bgcolor: Colors.primaryLight, color: Colors.primary }}
          >
            <FilterAlt />
          </IconButton>
        </Box>

        <Collapse in={filterCollapse}>
          <Grid container spacing={2} justifyContent="space-between">
            <Fragment>
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Search</InputLabel>
                <InputField
                  fullWidth
                  value={search}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ DataFilter ~ e.target.value:",
                      e.target.value
                    );
                    setSearch(e.target.value);
                  }}
                  placeholder={"Search"}
                />
              </Grid>
              <Grid item md={6} sx={{ width: "100%" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  fullWidth
                  value={status}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ DataFilter ~ e.target.value:",
                      e.target.value
                    );
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">InActive</MenuItem>
                </Select>
              </Grid>
            </Fragment>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                onClick={clearData}
                // variant="outlined"
                sx={{
                  color: Colors.black,
                  mx: 1,
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                }}
              >
                Clear
              </Button>
              <Button
                onClick={filterData}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  color: Colors.white,
                  background: Colors.primary,
                  "&:hover": {
                    background: Colors.primary,
                  },
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
}
function TablePaginationActions(props) {
  const theme = useTheme;
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

export default function CustomerDetails() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [getUsers, setGetUsers] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteialog, setOpenDeleteialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [names, setNames] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [comparisonCsvData, setComparisonCsvData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [items, setItems] = useState([]);

  const { setStorageItem, getStorageItem } = Storage();
  const [user, setUser] = useState(getStorageItem("user"));

  const csvLink = useRef();
  const navigate = useNavigate()
  const decodedToken = jwtDecode(user.token);
  const userIdFromToken = decodedToken.id;
  const company_id = userIdFromToken;

  let sleep = () => new Promise((r) => setTimeout(r, 2000));
  const tableHead = [
    "Sr",
    "Name",
    "Email",
    "Assigned Projects",
    "Phone Number",
    "Designation",
    "Role",
    "Status",
    "Action",
  ];
  const sortByStatus = (page, rows, search, status, serviceId) => {
    console.log("🚀 ~ sortByStatus ~ search:", search);
    setSearch(search);
    setStatus(status);
    setSelectedId(serviceId);
    setLimit(10);
    setPage(0);
    getUser(
      page,
      rows,
      search ? search : "",
      status ? status : "",
      company_id ? company_id : "",
      serviceId ? serviceId : null
      // fromDate ? fromDate : null,
      // toDate ? toDate : null
    );
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    getUser(newPage + 1, limit, search, status, company_id, selectedId);
  };
  const handleChangeRowsPerPage = (event) => {
    setLimit(Math.floor(event.target.value));
    setPage(0);
    getUser(1, event.target.value, search, status, company_id, selectedId);
  };
  const getUser = async (newPage, rows, search, status, companyId) => {
    setLoading(true);
    const { data, responseCode } = await UserServices.getUser(
      newPage ? newPage : 1,
      rows ? rows : "",
      search ? search : "",
      status ? status : "",
      companyId ? company_id : ""
    );

    try {
      await sleep();
      const filteredUser = data?.output?.users?.filter(
        (userData) => userData.role != "admin" && userData.role != "company"
      );
      console.log(filteredUser);
      // console.log(filteredUser)
      setGetUsers(filteredUser);
      setCount(data?.output?.totalUsers);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleUpdate = (user) => {
    console.log(user);
    setOpenDialog(true);
    setSelectedUser(user);
  };

  useEffect(() => {
    if (selectedUser) {
      // setValue("name", selectedUser.name);
      // setValue("email", selectedUser.email);
      // setValue("phone", selectedUser.phone);
      // setValue("designation", selectedUser.designation);
      // setValue("status", selectedUser.status);
      // setSelectStatus(selectedUser.status);
      setValue("name", selectedUser?.name);
      setValue("phone", selectedUser?.phone);
      setValue("emergency_contact", selectedUser?.emergency_contact);
      setValue("nic", selectedUser?.nic);
      setValue("dob", selectedUser?.dob);
      setValue("designation", selectedUser?.designation);
      setValue(
        "joining_date",
        moment(selectedUser?.joining_date).format("YYYY-MM-DD")
      );
      setValue("dob", moment(selectedUser?.dob).format("YYYY-MM-DD"));
      setValue("job_timings", selectedUser?.job_timings);
      setValue("marital_status", selectedUser?.marital_status);
      setValue("highest_education", selectedUser?.highest_education);
      setValue("home_address", selectedUser?.home_address);
      setValue("salary", selectedUser?.salary);
    }
  }, [openDialog, selectedUser, setValue]);

  const handleStatusChange = (event) => {
    setSelectStatus(event.target.value);
    setValue("status", event.target.value);
  };
  ///update user
  let desiredArray = selectedUser
    ? selectedUser.projectIds
        ?.map((id) => ({ _id: id?._id, hour: 0 }))
        .filter((userObj) => userObj._id)
    : [];
  console.log("Desired Array:", desiredArray);

  let selectedArray = personName
    ? personName
        .map((id) => ({ _id: id?._id, hour: 0 }))
        .filter((userObj) => userObj._id)
    : [];
  console.log("Selected Array:", selectedArray);

  let seen = new Set();
  let mergedArray = [...desiredArray, ...selectedArray].filter((userObj) => {
    if (seen.has(userObj._id)) {
      return false;
    } else {
      seen.add(userObj._id);
      return true;
    }
  });
  console.log("Merged Array:", mergedArray);

  const onSubmit = async (formData) => {
    // let obj = {
    //   name: formData.name,
    //   projectIds: selectedUser?.projectIds.map((id) => ({ _id: id?._id, hour: 0 })),
    //   // projectIds: mergedArray,
    //   email: formData.email,
    //   phone: formData.phone,
    //   designation: formData.designation,
    //   status: formData.status,
    // };
    console.log(formData);
    try {
      const { data, responseCode, message } = await UserServices.updateUser(
        selectedUser?._id,
        formData
      );
      setLoading(true);
      SuccessToaster(message);
      await sleep();
      setOpenDialog(false);

      getUser("", "", search, status, company_id);
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleGetProjects = async (
    page,
    limit,
    search,
    assignedUser,
    priority,
    status
  ) => {
    try {
      const { data, responseCode, message } = await ProjectServices.getProject(
        page,
        limit,
        search,
        assignedUser ? assignedUser : "",
        priority ? priority : "",
        status ? status : ""
      );
      setNames(data?.output?.projects);
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

  useEffect(() => {
    getUser("", "", search, status, company_id);
    handleGetProjects(1, 1000, "", "", "", "");
  }, []);

  const downloadCsv = () => {
    setCsvLoading(true); // Set loading state to true to show loading indicator

    try {
      // Define CSV headers as per the columns in the table
      const csvHeaders = [
        { label: "SR", key: "sr" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Assigned Projects", key: "assignedProjects" },
        { label: "Phone Number", key: "phoneNumber" },
        { label: "Emergency Contact No", key: "emergency_no" },
        { label: "CNIC No", key: "nic" },
        { label: "Designation", key: "designation" },
        { label: "Date of Birth", key: "dob" },
        { label: "Joining Date", key: "joining_date" },
        { label: "Marital Status", key: "marital_status" },
        { label: "Highest Education", key: "highest_education" },
        { label: "Job Timings", key: "job_timings" },
        { label: "Home Address", key: "home_address" },
        { label: "Role", key: "role" },
        { label: "Status", key: "status" },
      ];

      // Prepare the data from your table (getUsers state) for CSV
      const csvData = getUsers.map((user, index) => ({
        sr: index + 1,
        name: user.name,
        email: user.email,
        assignedProjects: user.projectIds
          .map((project) => project.name)
          .join(", "),
        phoneNumber: user.phone,
        emergency_no: user.emergency_contact,
        nic: user.nic,
        designation: user.designation,
        dob: user.dob,
        joining_date: user.joining_date,
        marital_status: user.marital_status,
        highest_education: user.highest_education,
        job_timings: user.job_timings,
        home_address: user.home_address,
        role: user.role,
        status: user.status,
      }));

      // Set the prepared data for CSV export
      setCsvData([
        csvHeaders.map((header) => header.label),
        ...csvData.map((row) => Object.values(row)),
      ]);
    } catch (error) {
      console.error("Error generating CSV data: ", error);
      // Optionally show a toast or alert to the user
      ErrorToaster("Failed to generate CSV data: " + error.message);
    } finally {
      setCsvLoading(false); // Set loading state to false once the operation is complete
    }
  };

  useEffect(() => {
    if (csvData.length > 0) {
      csvLink?.current.link.click();
    }
  }, [csvData, comparisonCsvData]);




  const handleDeleteUser= async () => {
    try {
      const { responseCode ,message } = await UserServices.deleteUser(selectedUserId);
      if (responseCode === 200) {
        setOpenDeleteialog(false);
        SuccessToaster(message)
        getUser("", "", search, status, company_id);

      }
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error)
      console.log(error?.message);
    }
  };
  return (
    <>
      <Box sx={{ mt: 4, ml: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "600", color: Colors.primary }}
        >
          User List
        </Typography>
      </Box>
      <DataFilter
        sortData={(page, rows, search, company_id, serviceId) =>
          sortByStatus(page, rows, search, company_id, serviceId)
        }
        page={page}
        rows={limit}
        company_id={company_id}
      />
      {loading ? (
        <Loader width="40px" height="40px" />
      ) : (
        <Box sx={{ mt: 2, ml: 2,  mr: 2, }}>
            <CSVLink
              ref={csvLink}
              data={csvData}
              filename={`Users_List_${moment().format("DD-MMM-YYYY_HHmmss")}.csv`}
              target="_blank"
            ></CSVLink>
            <Box
              sx={{
                display: "flex",
                justifyContent: " end",
                width: "100%",
              
              }}
            >
              <Button
                sx={{ backgroundColor: Colors.primary, color: Colors.white}}
                onClick={() => downloadCsv()}
                loading={csvLoading}
              >
                Download Excel
              </Button>
            </Box>
          <TableContainer
            component={Paper}
            sx={{ height: "430px", overflow: "auto", mt: 2 }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ position: "sticky", top: 0 }}>
                <TableRow sx={{ backgroundColor: Colors.primary }}>
                  {tableHead.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{ fontWeight: "600", color: Colors.black }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getUsers?.length > 0 ? (
                  getUsers?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "rgb(239 240 246)" : " ",
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            width: "200px",
                          }}
                        >
                          {item?.image ? (
                            <a
                              href={`https://crm.mangotech-api.com${item.image}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  objectFit: "contain",
                                }}
                                src={`https://crm.mangotech-api.com${item.image}`}
                                alt="Profile"
                              />
                            </a>
                          ) : (
                            <img
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                objectFit: "contain",
                              }}
                              src={Images?.defaultImage}
                              alt="Profile"
                            />
                          )}

                          {item.name}
                        </Box>
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>
                        <Typography sx={{ width: "300px", fontSize: "15px" }}>
                          {item?.projectIds?.length > 0
                            ? item.projectIds
                                .map((item2, index) => item2.name)
                                .join(", ")
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.phone}</TableCell>
                      <TableCell>{item.designation} </TableCell>
                      <TableCell>{item.role}</TableCell>
                      <TableCell>{item.status ? item.status : "-"}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <Tooltip title="Edit">
                            <Box
                              onClick={() => handleUpdate(item)}
                              sx={{ cursor: "pointer", color: Colors.black }}
                            >
                              {" "}
                              <EditIcon />
                            </Box>
                          </Tooltip>
                          <Box
                          onClick={()=> {
                            setSelectedUserId(item?._id)
                            setOpenDeleteialog(true)}}
                          sx={{ cursor: "pointer", color: Colors.black }}>
                          <DeleteIcon />
                        </Box>
                          <Box
                          onClick={()=> {
                            setSelectedUserId(item?._id)
                            navigate("/attendance-report",{state:item?._id})
                            }}
                          sx={{ cursor: "pointer", color: Colors.black }}>
                          <AccessTimeIcon />
                        </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={tableHead?.length}>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "280px", // Adjust height as needed
                          fontSize: "25px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        No data
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Dialog for Update */}

          <SimpleDialog
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
            }}
            title="Edit User"
            selectedUser={selectedUser}
          >
            {/* <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
              <Grid sx={{ mt: 2 }} container spacing={2} alignItems="center">
                
                <Grid item xs={12} sm={6}>
                  <InputLabel>Name</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <Typography variant="caption" color="error">
                      Name is required
                    </Typography>
                  )}
                </Grid>

               
                <Grid item xs={12} sm={6}>
                  <InputLabel>Email</InputLabel>
                  <TextField
                    type="email"
                    fullWidth
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <Typography variant="caption" color="error">
                      Email is required
                    </Typography>
                  )}
                </Grid>

             
                <Grid item xs={12} sm={6}>
                  <InputLabel>Phone Number</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    {...register("phone", { required: true })}
                  />
                  {errors.phone && (
                    <Typography variant="caption" color="error">
                      phone number is required
                    </Typography>
                  )}
                </Grid>

              
                <Grid item xs={12} sm={6}>
                  <InputLabel>Designation</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    {...register("designation", { required: true })}
                  />
                  {errors.designation && (
                    <Typography variant="caption" color="error">
                      Designation is required
                    </Typography>
                  )}
                </Grid>
            

                <Grid item xs={12} sm={6}>
                  <InputLabel>Status</InputLabel>
                  <TextField
                    fullWidth
                    select
                    {...register("status")}
                    value={selectStatus} // Use local state for value
                    onChange={handleStatusChange}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">InActive</MenuItem>
                  </TextField>
                </Grid>

             
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    sx={{
                      background: Colors.primary,
                      color: Colors.white,
                      padding: "5px 25px",
                      "&:hover": {
                        background: Colors.primary,
                      },
                    }}
                  >
                    UPDATE
                  </Button>
                </Grid>
              </Grid>
            </Box> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Name</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("name")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Phone</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("phone")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Emergency Contact</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("emergency_contact")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>National Identity Card</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("nic")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Date of Birth</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("dob")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Designation</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("designation")}
                  />
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Joining Date</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("joining_date")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Job Timings</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Mon-Fri 9:00 am to 6:00 pm, Sat: 9:00 am to 2:00 pm"
                    {...register("job_timings")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Marital Status</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("marital_status")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Highest Education</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...register("highest_education")}
                  />
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Salary</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    
                    {...register("salary")}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <InputLabel shrink>Home Address</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={2}
                    {...register("home_address")}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    sx={{
                      padding: "5px 38px",
                      color: Colors.white,
                      background: Colors.primary,
                      ":hover": {
                        background: Colors.primary,
                      },
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </SimpleDialog>

          {/* Dialog for delete */}

          <SimpleDialog
        open={openDeleteialog}
        onClose={() => setOpenDeleteialog(false)}
        border={`4px solid ${Colors.primary}`}
        title="Are you sure you want to delete?"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            mt: 5,
          }}
        >
          <Box
            onClick={() => setOpenDeleteialog(false)}
            sx={{
              background: Colors.black,
              color: Colors.white,
              borderRadius: "10px",
              px: 5,
              py: 2,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            No, Cancel
          </Box>
          <Box
            sx={{
              color: Colors.black,
              background: Colors.primary,
              borderRadius: "10px",
              px: 5,
              py: 2,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={handleDeleteUser}
          >
            Yes, Confirm
          </Box>
        </Box>
      </SimpleDialog>

          <Box sx={{ mt: 3, mb: 2 }}>
            <Table component={Paper} sx={{ borderRadius: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: Colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={count === undefined ? 0 : +count}
                rowsPerPage={limit}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </Box>
        </Box>
      )}
    </>
  );
}
