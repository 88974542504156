import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "../../../Assets/Styles/Colors";
import { useForm } from "react-hook-form";
import UserServices from "../../../Api/UserServices/User.index";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../Components/Toaster/Toaster";
import MultiSelectAutocomplete from "../../../Components/MultSelectInput/MultiSelectInput";
import ProjectServices from "../../../Api/ProjectServices/Project.index";
import { useNavigate } from "react-router-dom";
import { ErrorHandler } from "../../../Utils/ErrorHandler";

function Form() {

  const [selectedUser, setSelectedUser] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [names, setNames] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [selectStatus, setSelectStatus] = useState("active");
  const [personName, setPersonName] = useState([]);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Function to handle form submission
  const onSubmit = async (formData) => {
    const obj = {
      ...formData,
      role:"user",
      projectIds: personName.map(id => ({ _id: id?._id, hour: 0 }))
    };
    console.log(obj);
    try {
    const { data, responseCode, message } = await UserServices.createUser(obj);
      SuccessToaster(message);
      navigate("/userList");
      console.log(responseCode);
    } catch (error) {
      ErrorHandler(error)
      ErrorToaster(error.message);
      console.log(error.message);
    }
  };
  const handleGetProjects = async (page, limit, search, assignedUser,priority,status) => {
    try {
      const { data, responseCode, message } = await ProjectServices.getProject(
        page,
        limit,
        search,
        assignedUser ? assignedUser : "",
        priority?priority:"",
        status?status:""
      );
      setNames(data?.output?.projects);
      setTotalCount(data?.output?.totalProjects); 
      
    } catch (error) {
      console.error("Error while fetching users:", error);
    }
  };

 
  useEffect(() => {
   
      handleGetProjects(1, 1000, "", "", "","");
    
  }, []); 

  
  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Box
          sx={{
            backgroundColor: Colors.primary,
            borderRadius: "10px",
            minHeight: "50vh",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            width: "95%",
            margin: "0 auto",
            color: Colors.black,
            position: "relative",
          }}
        >
          <Box sx={{ pt: 3 }}>
            <Box>
              <h1>Create User</h1>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.smokeWhite,
          boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
          p: 2,
          // m: 2,
          position: "absolute",
          // top: {
          //   xs: "70%",
          //   sm: "55%",
          //   md: "50%",
          // },
          top: {
            xs: "74%",
            sm: "400px",
            md: "400px",
          },
          width: "85%",
          right: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ mt: 2 ,alignItems: "flex-start"}} container spacing={2} alignItems="center">
            {/* Name */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Name</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("name", { required: "name is required" })}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Email</InputLabel>
              <TextField
                type="email"
                fullWidth
                {...register("email", { required: "Email  is required" })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              
            
            </Grid>

            {/* Password */}
            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Password</InputLabel>
              <TextField
                type="password"
                fullWidth
                {...register("password", { required: true })}
              />
              {errors.password && (
                <Typography variant="caption" color="error">
                  password number is required
                </Typography>
              )}
            </Grid> */}
            {/* Phone */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Phone Number</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <Typography variant="caption" color="error">
                  phone number is required
                </Typography>
              )}
            </Grid>

            {/* Designation */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Assign Projects</InputLabel>
              <MultiSelectAutocomplete
                names={names}
                onClick={handleGetProjects}
                sx={{ width: "100%" }}
                personName={personName}
                setPersonName={setPersonName}
                selectedUser={selectedUser}
                // register={register("projectIds" ,{required:"true"})}
              />
            {/* {errors.projectIds && (
                <Typography variant="caption" color="error">
                  Assigned Project is required
                </Typography>
              )} */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Designation</InputLabel>
              <TextField
                type="text"
                fullWidth
                {...register("designation", { required: true })}
              />
              {errors.designation && (
                <Typography variant="caption" color="error">
                  Designation is required
                </Typography>
              )}
            </Grid>
            {/* Role */}
            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Role</InputLabel>
              <Select
                fullWidth
                defaultValue="user"
                {...register("role")}
              >
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="company">Company</MenuItem>
              </Select>
            
            </Grid> */}

                {/* status */}

            <Grid item xs={12} sm={6}>
              <InputLabel>Status</InputLabel>
              <TextField
                fullWidth
                select
                {...register("status", { required: true })}
                value={selectStatus}
                onChange={(e) => setSelectStatus(e.target.value)}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">InActive</MenuItem>
               
               
              </TextField>
             
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                sx={{
                  display:"flex",
                  margin:"0 auto",
                  mt:1, 
                  background:
                    Colors.primary,
                  color: Colors.black,
                  padding: "5px 25px",
                  "&:hover": {
                    background:
                      Colors.primary
                  },
                }}
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default Form;
